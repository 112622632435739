import React, { forwardRef } from 'react';
import style from "./SliderLField.module.scss";

export const SliderLField = forwardRef((props, ref) => {
    const { from, to, errors, register, ...rest } = props

    return <div className={`${style.main}`}>
        <div className={`${style.form}`}>{from}</div>
        <input
            {...rest}
            type="text"
            {...register(rest.name)}
            onChange={e => e.target.value = e.target.value.replace(/\D/, "")}
            className={`${style.handler} ${errors[rest.name] ? style.error : ""}`}
        />
        <div className={`${style.to}`}>{to}</div>
    </div>
})
