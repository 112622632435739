import React, { useState, useEffect } from 'react';
import style from "./Filter.module.scss";
import { useQuery } from 'react-query';
import { BiChevronDown } from "react-icons/bi";
import { useSearchParams } from "react-router-dom";
import { getTranslateApi } from '@http/Main/Translate';

export const Filter = ({ data }) => {
    let [searchParams, setSearchParams] = useSearchParams();
    let payload = Object.fromEntries([...searchParams])

    const obj = payload.filter ? payload.filter.split(",").reduce((acc, el) => {
        acc[el] = el;
        return acc;
    }, {}) : {}

    const [active, setActive] = useState({ selection: obj })
    const [toggle, setToggle] = useState([])
    const [translate, setTranslate] = useState({});

    const { data: resTranslate, refetch, isFetching } = useQuery([translate], getTranslateApi({
        text: translate, from: "zh-cn", to: "ru", type: "google"
    }), { refetchOnWindowFocus: false, enabled: false });

    useEffect(() => {
        const zhTranslate = data?.reduce((acc, el) => {
            acc.push(el.showText);
            el.subList?.data.forEach(text => acc.push(text.showText));
            return acc;
        }, []);
        setTranslate([...(zhTranslate || [])]);
    }, []);

    useEffect(() => {
        if (translate.length) refetch()
    }, [translate])

    useEffect(() => {
        let filterKeys = Object.keys(active.selection);
        filterKeys.length > 0 ? searchParams.set("filter", filterKeys.join(",")) : searchParams.delete("filter");
        setSearchParams(searchParams);
    }, [active])

    const handlerToggle = (idEvent) => setToggle((prevItems) => ({ ...prevItems, [idEvent]: !prevItems[idEvent] }));

    const handlerCheck = (params, event) => {
        setActive(state => ({
            ...state,
            selection: event.target.checked ? { ...state.selection, [params[0].value]: params[0].value } : Object.fromEntries(Object.entries(state.selection).filter(([key]) => key !== params[0].value))
        }));
    }

    return <div className={style.main}>
        {resTranslate && <div className={style.filter}>
            {data.map((el, i) => <ul key={i} onClick={() => handlerToggle(i)}>
                <li>
                    <div>{resTranslate?.find(({ orig }) => orig === el.showText)?.translate}</div>
                    <div><BiChevronDown size={20} /></div>
                </li>
                <ul className={toggle[i] ? style.toggle : ""}>
                    {el?.subList?.data?.map((val, k) => <li key={k}>
                        {val.params.length > 0 && <label htmlFor={val.params[0].value}>
                            <div>
                                <input onChange={event => handlerCheck(val.params, event)} id={val.params[0].value} checked={active.selection[val.params[0].value] ? true : false} type="checkbox" />
                            </div>
                            <div>{resTranslate?.find(({ orig }) => orig === val.showText)?.translate}</div>
                        </label>}
                    </li>)}
                </ul>
            </ul>)}
        </div>}
    </div>
}

