import React, { useEffect, useState } from 'react';
import style from "./Review.module.scss";
import { Modal } from '../index';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from 'react-redux';
import { dataFormatter } from '@utils/NFormatter';
import { getTranslateApi } from '@http/Main/Translate';

const Review = ({ data }) => {
    const dispatch = useDispatch()
    const [title, setTitle] = useState(null);

    useEffect(() => {
        if (title !== null) setTitle(null)
        if (data !== null) {
            let text = [];
            data.rateList?.map(el => {
                if (el.content.replace(/\p{Emoji_Presentation}/gu, '') !== '')
                    text.push(el.content.replace(/\p{Emoji_Presentation}/gu, ''))
                // text.push(el.skuInfo.trim())
            })
            let textTranslate = { text: text, from: "zh-cn", to: "ru", type: "google" }
            dispatch(getTranslateApi(textTranslate)).then(res => setTitle(res))
        }
        return () => { };
    }, []);

    let content;

    if (data.rateList?.length > 0) {
        content = <div className={style.review}>
            {data.rateList.map((el, k) => <div key={k}>
                <div>
                    <div>
                        <p>{el.userName}</p>
                        <div>
                            <p>{dataFormatter(el.dateTime)}</p>
                            <div><img src={`https://tao38.ru/images/ratings/${el.memberLevel}.gif`} alt={el.skuInfo} /></div>
                        </div>
                    </div>
                    <span>
                        {title ? <>{title.find(res => res.orig === el.content.replace(/\p{Emoji_Presentation}/gu, ''))?.translate}</> : <>
                            {el.content}
                            <div className={style.skeleton}></div>
                        </>}
                    </span>
                    {el.images && <div className={style.gallery}>
                        <Modal data={{
                            galleryID: "gallery" + uuidv4(),
                            props: el.images
                        }} />
                    </div>}
                </div>
            </div>)}
        </div>
    } else {
        content = <div className={style.review__null}>
            <h3>Об этом товаре отзывов пока нет</h3>
            <p>Попробуйте товар и поделитесь своим мнением!</p>
        </div>
    }

    return content
}

export default Review;
