import { configureStore } from "@reduxjs/toolkit";
import homeReducer from "./Reducers/Main/Home";
import searchReducer from "./Reducers/Header/Search";
import signReducer from "./Reducers/Header/Sign";
import productReducer from "./Reducers/Main/Product";
import searchGlobalReducer from "./Reducers/Main/Search";
import refreshReducer from "./Reducers/Component/Refresh";


export const store = configureStore({
    reducer: {
        search: searchReducer,
        searchGlobal: searchGlobalReducer,
        product: productReducer,
        home: homeReducer,
        refresh: refreshReducer,
        sign: signReducer,
    },
    devTools: true
})

window.store = store;