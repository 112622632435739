import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import "./fonts/Gilroy/stylesheet.css"
import './style/index.scss';
import { store } from "@store";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from 'react-query'

import App from './App';

const queryClient = new QueryClient()

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
