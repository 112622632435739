import { toast } from "react-toastify";
import { $host, $authHost } from "@http"

export const postOrderBuyNowApi = (payload) => async dispatch => {
    try {
        const { data } = await $host.post(`order/buynow`, payload)
        return data;
    } catch (e) {
        console.log(e.message)
    }
};

export const getOrderCustomerApi = (payload) => async dispatch => {
    try {
        const { data } = await $authHost.get(`order/customer`)
        return data;
    } catch (e) {
        console.log(e.message)
    }
};

