import React from 'react';
import style from "./SelectField.module.scss";
import SelectLib from 'react-select'
import { Controller } from "react-hook-form";

export const SelectField = ({
    appClassName,
    name,
    labal,
    control,
    options,
    placeholder = "",
    errors,
    disabled,
}) => {
    const customStyles = {
        indicatorSeparator: () => ({}),
        control: (provided, state) => ({
            borderRadius: "8px",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            boxSizing: "border-box",
            border: `1px solid ${errors[name] ? "#FF3333" : state.isFocused ? "rgb(80, 185, 70)" : "#dedcdc00"}`,
            transition: "all 0.3s ease 0s",
            backgroundColor: "#f7f7f7",
            backgroundImage: "none",
        }),
        valueContainer: () => ({
            padding: "15.5px 16px",
            display: "flex",
            alignItems: "center"
        }),
        option: (styles, { isFocused, isSelected }) => ({
            ...styles,
            background: isSelected
                ? 'rgba(80, 185, 70, 0.15)'
                : 'none',
            zIndex: 1,
            padding: "8px 12px",
            color: isSelected ? "#50b946" : "",
            fontWeight: "600",
            fontSize: "14px"
        }),
        menu: base => ({
            ...base,
            zIndex: 100,
            padding: "12px 0px",
            border: '0px',
            boxShadow: "rgb(90 112 114 / 8%) 0px 4px 18px",
            borderRadius: "8px"
        }),
        singleValue: () => ({
            fontSize: "16px"
        })
    }
    return <div className={`${style.main} ${errors[name] ? style.error : ""}`}>
        {labal && <h4>{labal}</h4>}
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <SelectLib
                    {...field}
                    styles={customStyles}
                    options={options}
                    placeholder={placeholder}
                    className={`${errors[name] && 'material-ui__select__container__active'}`}
                    classNamePrefix="material-ui__select"
                    isSearchable={false}
                    isDisabled={disabled}
                />
            )}
        />
        {errors[name] && <p>{errors[name].message}</p>}
    </div>
}
