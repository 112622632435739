import React, { useEffect, useState, useMemo, useCallback } from 'react';
import style from "./Search.module.scss";
import withSizes from 'react-sizes';
import { Filter } from "./Component";
import { useQuery } from 'react-query';
import { BsSliders } from "react-icons/bs"
import { AiOutlineLeft } from "react-icons/ai"
import { nFormatter } from '@utils/NFormatter';
import { getListItemsApi } from '@http/Main/List';
import { getLangApi } from '@http/Main/Translate';
import { useSearchParams } from "react-router-dom";
import { http_build_query } from "@utils/NFormatter";
import { Refresh, Loading, MasonryContainer, Select, Crum } from '@components';

const Search = ({ large }) => {
    const [payloadItem, setPayloadItem] = useState({ page: 1 })
    let [searchParams, setSearchParams] = useSearchParams();
    let payload = Object.fromEntries([...searchParams])
    const [showButton, setShowButton] = useState(false)

    let payloadData = { ...payloadItem, ...payload }

    const payloadQueryString = useMemo(() => http_build_query(payload), [payload]);
    const getLangQueryString = useCallback(() => http_build_query({ text: payload.q, to: "zh-Hans" }), [payload.q]);

    const { data, isFetching: getLangFerch } = useQuery([payloadQueryString], getLangApi(getLangQueryString()), { refetchOnWindowFocus: false });
    const { data: resItem, refetch, isFetching } = useQuery([http_build_query(payloadData)], getListItemsApi(payloadData), { refetchOnWindowFocus: false, enabled: false });

    const isDataReady = !!data?.language?.from;
    const { text, translation, language } = data || {};

    const handlerSort = (id) => {
        searchParams.delete("page")
        searchParams.delete("filter")
        searchParams.set("sort", id);
        setSearchParams(searchParams)
    }

    const showNewItem = (id) => {
        id = parseInt(id)
        setPayloadItem(prev => ({ ...prev, page: id + 1 }));
        searchParams.set("page", id + 1);
        setSearchParams(searchParams)
    }

    useEffect(() => {
        if (data?.language?.from) {
            setPayloadItem(prev => ({ ...prev, query: language.from === "ru" ? translation : text }));
        }
    }, [isDataReady]);

    useEffect(() => {
        if (payloadItem.query) refetch()
    }, [payloadItem])

    const { items, listHeader, pagination } = resItem || {};

    let sort = [
        { id: "_sale", name: "По заказам" },
        { id: "_coefp", name: "По умолчанию" },
        { id: "_ratesum", name: "Высокий рейтинг" },
        { id: "_bid", name: "Дороже" },
        { id: "bid", name: "Дешевле" }
    ]

    let crum = [{ title: "Главное", link: "/" }, { title: "Каталог", link: "/cat" }, { title: text, link: `/search?q=${text}` }]

    if (getLangFerch || !resItem) return <Loading />;

    if (!items) return <Refresh reFetch={refetch} isFetching={isFetching} />;

    return <div className={style.main}>
        <aside className={`${style.filter} ${large ? style.large__header : ""} ${showButton ? style.large : ""}`}>
            {large && <div>
                <div><button onClick={() => { setShowButton(false) }}><AiOutlineLeft size={14} /><span>Назад</span></button></div>
                <div><span>Фильтры</span></div>
                <div><button>Сбросить</button></div>
            </div>}
            <div>
                <div className={style.content__header__counter}>
                    <div>
                        <div>
                            <Select value={sort.find(el => el.id === payloadData.sort)?.name || "По умолчанию"} name={"sortSelect"} data={sort} onSelect={(value) => handlerSort(value)} />
                        </div>
                    </div>
                </div>
                {listHeader && <Filter data={listHeader} />}
            </div>
        </aside>
        <div className={style.content}>
            <div className={style.content__header}>
                <div>
                    <Crum data={crum} />
                </div>
                <div className={style.content__header__title}>
                    <h1>{text}</h1>
                    {/* <div>{nFormatter(pagination.totalResults, 1)} товаров</div> */}
                    {large && <button className={style.largeButton} onClick={() => { setShowButton(true) }}>
                        <BsSliders />
                        <span>Фильтры</span>
                    </button>}
                </div>
            </div>
            <MasonryContainer block="search" data={items} />
            <div className={style.button}>
                <button
                    onClick={() => showNewItem(pagination.page)}>
                    <span>Показать еще</span>
                </button>
            </div>
        </div>
    </div>
}

const mapSizesToProps = ({ width }) => ({
    large: width < 1280,
})
export default withSizes(mapSizesToProps)(Search);
