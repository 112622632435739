import React from 'react';
import style from "./Main.module.scss";
import { TitleCard } from '@components';

export const Main = () => {
    return <div style={style.main}>
        <TitleCard name="Главная">

        </TitleCard>
    </div>
}

