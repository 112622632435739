import React, { useState, useEffect } from 'react';
import style from "./Banner.module.scss";
import "swiper/css";
import "swiper/css/navigation";
import randomColor from "randomcolor";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";

import SwiperCore, {
    Navigation
} from 'swiper';

SwiperCore.use([Navigation]);

const Banner = () => {
    const [color, setColor] = useState([])
    const navigationPrevRef = React.useRef(null)
    const navigationNextRef = React.useRef(null)

    useEffect(() => {
        setColor(colorGenerate())
    }, [])

    const colorGenerate = () => Array(3).fill().map((el) => randomColor({ format: 'rgba', alpha: 0.3 }))

    return <div className={style.main}>
        <div className={`${style.swiperPrev}`} ref={navigationPrevRef}>
            <BiChevronLeft size={40} />
        </div>
        <div className={`${style.swiperNext}`} ref={navigationNextRef}>
            <BiChevronRight size={40} />
        </div>
        <Swiper
            navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
            }}
            onBeforeInit={(swiper) => {
                swiper.params.navigation.prevEl = navigationPrevRef.current;
                swiper.params.navigation.nextEl = navigationNextRef.current;
            }}
            spaceBetween={20}>
            {Array(3).fill().map((el, i) => <SwiperSlide key={i} className={style.banner}>
                <Link style={{ background: color[i] }} to="/" >
                    <div>
                        <div>
                            <div>Выгодные предложения</div>
                            <div>Акция недели</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>
                                <div>
                                    <img loading="lazy" alt="" src="https://img.joomcdn.net/181508ee57b71b20f26b4a8a14a79f142b9165af_original.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
            </SwiperSlide>)}
        </Swiper>
    </div>
}

export default React.memo(Banner);
