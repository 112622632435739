import React from 'react';
import style from "./Form.module.scss";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { postOrderBuyNowApi } from "@http/Components/Order"
import { LField, Button, LFieldMask, SelectField } from '@components';

const schema = yup.object().shape({
    contry: yup.object().required("Обязательно"),
    firstName: yup.string().required("Обязательно"),
    phone: yup.string().required("Обязательно")
        .transform(value => value.replace(/\+992|\s|\(|\)|-|_/g, '')).min(9, "Не менее 9 символов"),
});

export const Form = ({ data, isModal }) => {

    const dispatch = useDispatch();

    const {
        register,
        control,
        formState: { errors },
        handleSubmit,
    } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const handlerSubmit = (client) => {
        const payload = {
            costomer: client,
            item: data
        }
        dispatch(postOrderBuyNowApi(payload)).then(res => res.success === 'ok' ? (isModal(false), toast.success('Ваша заявка принята!')) : toast.error(res.success))
    }

    const contryData = [
        {
            label: "Душанбе",
            value: 1
        },
        {
            label: "Худжанд",
            value: 2
        }
    ]

    return <div className={style.main}>
        <div>
            <LField
                id="firstName"
                name="firstName"
                type="text"
                label="Ваше имя"
                register={register}
                errors={errors} />
        </div>
        <div>
            <LFieldMask
                register={register}
                errors={errors}
                id="phone"
                name="phone"
                label="Ваш номер телефон"
                mask="+\9\92 (99) 999-99-99"
            />
        </div>
        <div>
            <SelectField
                control={control}
                name="contry"
                labal="Город"
                options={contryData}
                errors={errors}
            />
        </div>
        <div className={style.button}>
            <Button onClick={handleSubmit(handlerSubmit)}>Купить</Button>
        </div>
    </div>
}
