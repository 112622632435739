import React from 'react';
import style from "./Seller.module.scss"
import withSizes from 'react-sizes';
import { Link } from "react-router-dom";

const Seller = ({ data, isMobile }) => <div className={style.main}>
    <div>
        <div className={style.seller}>
            <Link to={{
                pathname: '/seller',
                search: `?id=${data.shopId}`,
            }}>
                <div>
                    <div>
                        <div>
                            <div>
                                <img src={data.shopIcon !== '' ? data.shopIcon + "_120x120.jpg" : "https://gw.alicdn.com/imgextra/i1/O1CN01bZjlSC1Y3sVQ6FzyO_!!6000000003004-2-tps-258-258.png_110x10000.jpg_.webp"} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <p>{data.sellerNick}</p>
            </Link>
            <div>
                {data.evaluates.map((el, i) => <div key={i}>
                    <div>
                        {el.type === "post" ? "Доставка" : el.type === "serv" ? "Сервис" : "Описания"}
                    </div>
                    <div>{el.score}</div>
                </div>)}
            </div>
        </div>
    </div>
</div>

const mapSizesToProps = ({ width }) => ({
    isMobile: width < 768,
    tablet: width < 992,
})

export default React.memo(withSizes(mapSizesToProps)(Seller));
