import { $host } from "@http"
import { homeListAC } from "@store/Reducers/Main/Home";
export const getHomeTopApi = (id = "209021", page = "1") => async dispatch => {
    try {
        const { data } = await $host.get(`home/recommend?page=${page}`)
        return data;
    } catch (e) {
        console.log(e.message)
    }
};

export const getHomeCategoryApi = (id = "209021", page = "1") => async dispatch => {
    try {
        const { data } = await $host.get(`home/kangaroo?page=${page}&cats=${id}`)
        if (!data.hasOwnProperty("catId")) {
            dispatch(getHomeCategoryApi(id, page))
            return;
        }
        dispatch(homeListAC(data))
    } catch (e) {
        console.log(e.message)
    }
};

