import React from 'react';
import style from "./Crum.module.scss";
import { Link } from "react-router-dom";

const Crum = ({ data }) => <div className={style.main}>
    {data.map((el, i) => <span key={i}>
        <Link to={el.link}>{el.title}</Link>
    </span>)}
</div>

export default React.memo(Crum);