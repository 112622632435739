import React from 'react';
import style from "./Main.module.scss";
import { Route, Routes } from "react-router-dom";
import { Home, Catalog, Product, Profile, Detail, Image, SellerItem, Search, List } from './index';

const Main = () => <main className={style.main}>
    <div>
        <div className="container">
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/cat" element={<Catalog />} />
                <Route path="/list" element={<List />} />
                <Route path="/search" element={<Search />} />
                <Route path="/image" element={<Image />} />
                <Route path="/product" element={<Product />} />
                <Route path="/detail" element={<Detail />} />
                <Route path="/seller" element={<SellerItem />} />
                <Route path="/profile/*" element={<Profile />} />
            </Routes>
        </div>
    </div>
</main>

export default Main;
