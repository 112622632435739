import { $host } from "@http"
import { toast } from "react-toastify";
import { http_build_query } from "@utils/NFormatter";

export const getProductApi = (id) => async dispatch => {
    try {
        const { data } = await $host.get(`product?id=${id}`)
        return data;
    } catch (e) {
        console.log(e.message)
    }
};

export const getItemApi = (id) => async dispatch => {
    try {
        const { data } = await $host.get(`item?id=${id}`)
        return data;
    } catch (e) {
        console.log(e.message)
    }
};

export const getPriceItemApi = (payload) => async dispatch => {
    try {
        let { id, skuId } = payload
        const { data } = await $host.get(`product/price?id=${id}&config=${skuId}`)
        return data;
    } catch (e) {
        console.log(e.message)
    }
};

export const getSuperBuyApi = (id) => async dispatch => {
    try {
        const { data } = await $host.get(`product?id=${id}`)

        // if (!data.hasOwnProperty("state")) {
        //     dispatch(getSuperBuyApi(id))
        //     return;
        // }

        return data;
    } catch (e) {
        console.log(e.message)
    }
};

export const getItemRecommendApi = (id, seller) => async dispatch => {
    try {
        const { data } = await $host.get(`product/shoprecommend?id=${id}&seller=${seller}`)
        return data;
    } catch (e) {
        console.log(e.message)
    }
};
export const getItemWrldApi = (id) => async dispatch => {
    try {
        const { data } = await $host.get(`product/json?id=${id}`)
        if (!data.hasOwnProperty("title")) {
            toast.error(data)
        }

        return data;
    } catch (e) {
        console.log(e.message)
    }
};
export const getItemRecommendBottomApi = (payload) => async dispatch => {
    try {
        const { data } = await $host.get(`product/recommend?${http_build_query(payload)}`)
        if (!data.hasOwnProperty('result')) return null
        return data;
    } catch (e) {
        console.log(e.message)
    }
};

export const getDescApi = (id) => async dispatch => {
    try {
        const { data } = await $host.get(`product/desc?id=${id}`)
        if (!data.hasOwnProperty("blocks")) return null
        return data;
    } catch (e) {
        console.log(e.message)
    }
};

