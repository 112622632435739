import React from 'react';
import style from "./View.module.scss";
import { Header, Footer, Main } from "./index"
const View = () => {
    return <div className={style.main}>
        <Header />
        <Main />
        <Footer />
    </div>
}

export default View;
