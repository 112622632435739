import React, { forwardRef } from 'react';
import style from "./LFieldMask.module.scss";
import InputMask from "react-input-mask";

export const LFieldMask = forwardRef((props, ref) => {

    const { mask, errors, register, label, type, ...rest } = props

    return <div className={`${style.main} ${errors[rest.name] ? style.error : ''}`}>
        {label && <label htmlFor={rest.id}>{label}</label>}
        <InputMask
            mask={mask}
            type="text"
            {...register ? register(rest.name) : null}
            {...rest}
        />
        {errors[rest.name] && <p>{errors[rest.name].message}</p>}
    </div >
})

