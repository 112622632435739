import React from 'react';
import style from "./TMenu.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/css/navigation"


import SwiperCore, {
    FreeMode, Navigation
} from 'swiper';

SwiperCore.use([FreeMode, Navigation]);

const TMenu = ({ list }) => {

    const navigationPrevRef = React.useRef(null)
    const navigationNextRef = React.useRef(null)

    return <Swiper slidesPerView="auto"
        navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
        }}
        onBeforeInit={(swiper) => {
            swiper.params.navigation.prevEl = navigationPrevRef.current;
            swiper.params.navigation.nextEl = navigationNextRef.current;
        }}
        className={style.main}>
        {list.map((el, i) => <SwiperSlide key={i} >
            {el}
        </SwiperSlide>)}
        <div className={`swiper-button-prev ${style.swiperPrev}`} ref={navigationPrevRef} />
        <div className={`swiper-button-next ${style.swiperNext}`} ref={navigationNextRef} />
    </Swiper>
}

export default React.memo(TMenu)
