import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    focus: false,
}

const searchReducer = createSlice({
    name: 'searchReducer',
    initialState,
    reducers: {
        focusAC(state, action) {
            state.focus = action.payload
        }
    }
})

export const {
    focusAC,
} = searchReducer.actions
export default searchReducer.reducer