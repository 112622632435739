import React, { useState, useEffect } from 'react';
import style from "./SliderContainer.module.scss"
import "swiper/css";
import "./SwiperCustom.scss"
import { Card } from '@components';
import withSizes from 'react-sizes';
import { useDispatch } from 'react-redux';
import { Swiper, SwiperSlide } from "swiper/react";


const SliderContainer = ({ data, isMobile }) => {
    let content;

    if (data === null) {
        content = <div>Loadding</div>;
    } else if (data.length === 0) {
        content = <div>пусто!</div>;
    } else {
        content = <div className={style.main}>
            <Swiper
                slidesPerView={2}
                spaceBetween={8}
                watchSlidesProgress={true}
                runCallbacksOnInit={true}
                breakpoints={{
                    375: {
                        slidesPerView: 3,
                        spaceBetween: 8,
                    },
                    545: {
                        slidesPerView: 4,
                        spaceBetween: 10,
                    },
                    768: {
                        slidesPerView: 3,
                        spaceBetween: 16,
                    },
                    992: {
                        slidesPerView: 4,
                        spaceBetween: 20,
                    },
                    1280: {
                        slidesPerView: 5,
                    }
                }}
                className={isMobile ? "" : "customSwiper"}>
                {data.map((el, i) => {
                    let obj = {
                        image: el.img,
                        id: el.itemId,
                        sold: el.sold30Days,
                        discountPrice: el.price,
                        productTitle: el.title,
                    }
                    if (el.reservePrice && el.reservePrice > el.price) {
                        obj.discountPrice = el.price;
                        obj.originalPrice = el.reservePrice;
                        obj.discount = `${Math.round(el.price * 100 / el.reservePrice - 100)}`
                    } else {
                        obj.discountPrice = el.price;
                    }
                    return <SwiperSlide className={style.swiper} key={i}>
                        <Card data={obj} />
                    </SwiperSlide>
                })}
            </Swiper>
        </div >
    }

    return content
}
const mapSizesToProps = ({ width }) => ({
    isMobile: width < 768,
})
export default React.memo(withSizes(mapSizesToProps)(SliderContainer));