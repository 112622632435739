import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    login: null,
}

const signReducer = createSlice({
    name: 'signReducer',
    initialState,
    reducers: {
        loginAC(state, action) {
            state.login = action.payload
        }
    }
})

export const {
    loginAC,
} = signReducer.actions
export default signReducer.reducer