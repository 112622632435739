import React, { useState } from 'react';
import style from './Sign.module.scss';
import * as yup from "yup";
import { In, Up } from "./index";
import { Button } from '@components';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { postSignInApi, postSignUpApi } from "@http/Header/Sign";
import { yupResolver } from "@hookform/resolvers/yup";

const upSchema = yup.object().shape({
    upEmail: yup.string().email("Неверный формат E-mail").required("Обязательно"),
    upPassword: yup.string().min(6, 'Минимально 6 символов').max(50, 'максимально 50 символов').required("Обязательно"),
    upPhone: yup.string().required("Обязательно")
        .transform(value => value
            .replace(/\+992/g, '')
            .replace(/\s.*?/g, '')
            .replace(/\(/g, '')
            .replace(/\)/g, '')
            .replace(/-/g, '')
            .replace(/_/g, ''))
        .min(9, "Не менее 9 символов"),
});

const inSchema = yup.object().shape({
    inEmail: yup.string().email("Неверный формат E-mail").required("Обязательно"),
    inPassword: yup.string().min(6, 'Минимально 6 символов').max(50, 'максимально 50 символов').required("Обязательно"),
});

export const Sign = () => {
    const dispatch = useDispatch();
    const [activeLink, setActiveLink] = useState(true);
    let { login } = useSelector(state => state.sign)

    const {
        register,
        control,
        getValues,
        formState: { errors },
        handleSubmit,
    } = useForm({
        mode: "onChange",
        resolver: yupResolver(activeLink ? inSchema : upSchema),
    });

    const handlerSubmit = (data) => {
        if (activeLink) {
            dispatch(postSignInApi({
                email: data.inEmail,
                pass: data.inPassword
            }))
        } else {
            dispatch(postSignUpApi({
                email: data.upEmail,
                pass: data.upPassword,
                phone: data.upPhone
            }))
        }

    }
    return <div className={style.main}>
        {activeLink ? <In
            getValues={getValues}
            errors={errors}
            register={register}
            control={control} /> : <Up
            getValues={getValues}
            errors={errors}
            register={register}
            control={control} />}

        <div className={style.button}>
            <div><Button onClick={handleSubmit(handlerSubmit)}>{activeLink ? "Войти" : "Регистрация"}</Button></div>
            <div><button onClick={() => setActiveLink(!activeLink)}>{activeLink ? "Создать аккаунт" : "Войти в аккаунт"} </button></div>
        </div>
    </div >
}

