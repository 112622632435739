import React, { useState, useEffect } from 'react';
import style from './Info.module.scss';
import withSizes from 'react-sizes';
import { Helmet } from "react-helmet";
import { HiShoppingCart } from "react-icons/hi"
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { getTranslateApi } from '@http/Main/Translate';
import { configSkuAC } from "@store/Reducers/Main/Product"
import { AiOutlineComment, AiFillHeart } from "react-icons/ai";
import { Button, Loading, Crum, Skeleton } from '@components';


const Info = ({ data, tablet, isMobile, isModal }) => {
    const dispatch = useDispatch()
    const [title, setTitle] = useState(null);
    const [skuActive, setSkuActive] = useState([])

    let isMounted = true;
    let [searchParams] = useSearchParams();
    let { id } = Object.fromEntries([...searchParams])
    let { configSku } = useSelector(state => state.product)

    const handlerSku = (sku) => {
        if (skuActive.some(e => e.propId === sku.propId)) {
            let newArr = [...skuActive];
            newArr[skuActive.findIndex(item => item.propId === sku.propId)] = sku
            setSkuActive(newArr)
        } else {
            setSkuActive([...skuActive, sku]); // or push
        }
    }

    useEffect(() => {
        if (data !== null && title === null) {
            let text = [];
            text.push(data.title)
            if (data?.skuBase.props) {
                Object.keys(data.skuBase.props).map(el => {
                    text.push(data.skuBase.props[el].find(res => res.propId).propName)
                    data.skuBase.props[el].map(val => {
                        text.push(val.valueName)
                    })
                })
            }
            let textTranslate = { text: text, from: "zh-cn", to: "ru", type: "google" }
            dispatch(getTranslateApi(textTranslate)).then(res => {
                if (isMounted) setTitle(res)
            })
        }
        return () => {
            isMounted = false
        };
    }, [data]);

    useEffect(() => {
        if (skuActive.length !== 0) setSkuActive([])
        if (title !== null) setTitle(null)
        return () => {
            if (title !== null) setTitle(null)
        }
    }, [searchParams])

    useEffect(() => {
        if (data && Object.keys(data.skuBase.props).length === skuActive.length) {
            dispatch(configSkuAC(data.skuBase.skus[data.skuBase.skus.findIndex(({ props }) => skuActive.every(skuElement => props.some(prop => prop.propId === skuElement.propId && prop.valueId === skuElement.valueId)))]))
        }
        return () => { dispatch(configSkuAC(null)) }
    }, [skuActive])

    if (tablet && data === null) return <Loading />

    return <div className={style.main}>
        {!tablet && <div>
            <Crum data={[{ title: "Главное", link: "/" }, { title: "Каталог", link: "/cat" }, { title: id, link: `/detail?id=${id}` }]} />
        </div>}
        <div className={style.title}>
            {data ? <>
                <Helmet>
                    <title>TajMarket - {title ? title.find(res => res.orig === data.title)?.translate : data.title}</title>
                </Helmet>
                <h1>{title ? title.find(res => res.orig === data.title)?.translate : data.title}</h1>
            </> : <Skeleton quantity={1} appClassName={style.skeleton} />}
            <div>
                <div>
                    <div>
                        <div>
                            <div>{data ? <img src={`https://tao38.ru/images/ratings/${data.creditLevel}.gif`} alt="" /> : <Skeleton quantity={1} appClassName={style.skeleton} />}</div>
                            {data ? <div>{data.goodRatePercentage} Продаж</div> : <Skeleton quantity={1} appClassName={style.skeleton} />}
                        </div>
                        <div>
                            <div><AiOutlineComment size={20} /></div>
                            {data ? <div>{data.rate} отзывов</div> : <Skeleton quantity={1} appClassName={style.skeleton} />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className={style.product_price_wrapper}>
            <div>
                <div className={style.product_price_wrapper__price}>
                    <div className={`${parseFloat(data?.price.originalPrice) > parseFloat(data?.price.promotionPrice) ? style.old : ''}`}>
                        {data ? <>
                            <div className={style.price_new}>{configSku ? configSku.price.price : parseFloat(data?.price.promotionPrice)} СМН</div>
                            {configSku === null && parseFloat(data?.price.originalPrice) > parseFloat(data?.price.promotionPrice) && <div className={style.price_old_wrapper}>
                                <div>{parseFloat(data?.price.originalPrice)} СМН</div>
                            </div>}
                            {configSku?.price.price < parseFloat(data?.price.originalPrice) && <div className={style.price_old_wrapper}>
                                <div>{parseFloat(data?.price.originalPrice)} СМН</div>
                            </div>}
                        </> : <Skeleton quantity={1} appClassName={style.skeleton} />}
                    </div>
                    {!isMobile && <div>
                        <div>
                            <Button disabled={data?.skuBase.skus.length === 0 ? false : configSku ? false : true} style={{ opacity: data?.skuBase.skus.length === 0 ? "1" : configSku ? "1" : "0.7" }} onClick={isModal} svg={<HiShoppingCart size={20} />}>
                                <span>В корзину</span>
                            </Button>
                        </div>
                        <Button appClassName={style.heart} svg={<AiFillHeart size={24} />} />
                    </div>}
                </div>
                <div className={style.price_card}>
                    <div>{data ? <>{configSku ? configSku.quantity : data.quantity.quantity}/шт</> : <Skeleton quantity={1} appClassName={style.skeleton} />}</div>
                    <div>{data ? <>
                        {configSku?.quantity > 10 && "В наличии много"}
                        {configSku?.quantity < 10 && "В наличии осталась мало"}
                        {configSku === undefined && "Нет в наличии"}
                        {configSku === null && "В наличии много"}
                    </> : <Skeleton quantity={1} appClassName={style.skeleton} />}</div>
                </div>
                <div className={style.sku}>
                    {data?.skuBase.props && <>
                        {Object.keys(data.skuBase.props).map((el, i) => <div key={i}>
                            <div>
                                <h2>{title ? title.find(res => res.orig === data.skuBase.props[el].find(res => res.propId).propName)?.translate : data.skuBase.props[el].find(res => res.propId).propName}</h2>
                                {skuActive.some(e => e.propId === data.skuBase.props[el][0].propId) && <div>
                                    {title ? title.find(res => res.orig === skuActive[skuActive.findIndex(item => item.propId === data.skuBase.props[el][0].propId)].valueName)?.translate : skuActive[skuActive.findIndex(item => item.propId === data.skuBase.props[el][0].propId)].valueName}
                                </div>}
                            </div>
                            <ul>
                                {data.skuBase.props[el].map((val, k) => <li onClick={() => handlerSku(val)} className={`${val.imgUrl ? style.image : ""} ${skuActive.some(e => e.valueId === val.valueId) ? style.active : ""}`} style={{ background: val.imgUrl ? `url(${val.imgUrl}_50x50q90.jpg) center no-repeat` : "" }} key={k}>
                                    <span>{title ? title.find(res => res.orig === val.valueName)?.translate : val.valueName}</span>
                                </li>)}
                            </ul>
                        </div>)}
                    </>}
                </div>
            </div>
        </div>
    </div >
}

const mapSizesToProps = ({ width }) => ({
    isMobile: width < 768,
    tablet: width < 992,
})

export default React.memo(withSizes(mapSizesToProps)(Info));
