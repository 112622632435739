import React, { useEffect, useState } from 'react';
import style from './GroupProps.module.scss';
import { useDispatch } from 'react-redux';
import { getTranslateApi } from '@http/Main/Translate';

const Groupprops = ({ data }) => {
    const dispatch = useDispatch()
    const [title, setTitle] = useState(null);

    useEffect(() => {
        let isMounted = true;
        if (title !== null) setTitle(null)
        if (data !== null && data.info.pageInitialProps.httpData.normalItemResponse.props.groupProps !== undefined) {
            let text = [];
            data.info.pageInitialProps.httpData.normalItemResponse.props.groupProps[0].基本信息.map(el => {
                text.push(Object.keys(el)[0])
                text.push(Object.values(el)[0])
            })
            let textTranslate = { text: text, from: "zh-cn", to: "ru", type: "google" }
            dispatch(getTranslateApi(textTranslate)).then(res => {
                if (isMounted) setTitle(res)
            });
        }
        return () => {
            isMounted = false;
        };
    }, [data]);

    let content;

    if (data === null) {
        content = <div>Loading</div>
    } else if (data.info.pageInitialProps.httpData.normalItemResponse.props.groupProps !== undefined) {
        content = <div className={style.main}>
            <div>
                {data.info.pageInitialProps.httpData.normalItemResponse.props.groupProps[0].基本信息.map((el, i) => <div key={i}>
                    <div>
                        <div>
                            {title ? title.find(res => res.orig === Object.keys(el)[0])?.translate : <>{Object.keys(el)[0]}</>}
                            {title === null && <div className={style.skeleton}></div>}
                        </div>
                        <div className={style.dot_leaders}></div>
                        <div>
                            {title ? title.find(res => res.orig === Object.values(el)[0])?.translate : <>{Object.values(el)[0]}</>}
                            {title === null && <div className={style.skeleton}></div>}
                        </div>
                    </div>
                </div>)}
            </div>
        </div>
    } else {
        content = <div>Нету информация!</div>
    }

    return content
}

export default React.memo(Groupprops);
