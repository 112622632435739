import { $host } from "@http"

export const getTranslateApi = (text = []) => async dispatch => {
    try {
        const { data } = await $host.post(`/translate`, text)
        return data;
    } catch (e) {
        console.log(e.message)
    }
};

export const getLangApi = (payload) => async dispatch => {
    try {
        const { data } = await $host.get(`/getLang?${payload}`)
        return data;
    } catch (e) {
        console.log(e.message)
    }
};

