import React from 'react';
import style from "./Card.module.scss";
import { Link } from "react-router-dom";
import { AiFillStar, AiOutlineStock } from "react-icons/ai"
import { HiShoppingCart } from "react-icons/hi";
import { AiFillHeart } from "react-icons/ai";
import withSizes from 'react-sizes';
import { Skeleton } from '@components';

const Card = ({ data, isMobile }) => <div className={style.main}>
    <div className={style.image}>
        <Link to={{
            pathname: '/detail',
            search: `?id=${data.id}`,
        }}>
            <div>
                <div>
                    <img alt="" loading="lazy" src={`${data.image}_220x10000Q75.jpg`} />
                </div>
            </div>
        </Link>
        <div>
            <div>
                {data.originalPrice && <div className={style.discount}>
                    <span>
                        {data.discount}%
                    </span>
                </div>}
            </div>
            {!isMobile && <div>
                <AiFillHeart size={24} />
            </div>}
        </div>
    </div>
    <div className={style.content}>
        {!isMobile && <div className={style.meta}>
            <div>
                <div>
                    <AiFillStar size={15} />
                    <span>4.5</span>
                </div>
                <div>
                    <AiOutlineStock size={18} />
                    <span>{data.sold}</span>
                </div>
            </div>
            <div>
                13467/шт
            </div>
        </div>}
        {data.productTitle ? <div className={style.title}>
            <Link to={{
                pathname: '/detail',
                search: `?id=${data.id}`,
            }}>{data.productTitle}</Link>
        </div> : <div className={`${style.skeleton} ${style.title}`}><Skeleton quantity={2} /></div>}
        <div className={style.control}>
            <div className={style.price}>
                <div className={`${data.originalPrice ? style.PDiscount : ""}`}>
                    <span>
                        {data.discountPrice} СМН
                    </span>
                    {data.originalPrice &&
                        <span>{data.originalPrice} СМН</span>
                    }
                </div>
            </div>
            <div className={style.card}>
                {!isMobile ? <HiShoppingCart /> : <span>В корзину</span>}
            </div>
        </div>
    </div>
</div>

const mapSizesToProps = ({ width }) => ({
    isMobile: width < 768,
})

export default React.memo(withSizes(mapSizesToProps)(Card));
