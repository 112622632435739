import React, { useState, useEffect } from 'react';
import style from "./MasonryContainer.module.scss"
import { Card } from '@components';
import Masonry from 'react-masonry-css'

const MasonryContainer = ({ data, block }) => {
    const breakpointColumnsObj = {
        default: 4,
        1279: 4,
        991: 3,
        500: 2
    };

    if (data.length === 0) return <div>Нет товаров!</div>

    return <Masonry breakpointCols={breakpointColumnsObj} className={`${style.main ?? ''} ${style.masonryGrid}`} columnClassName={style.masonryGridColumn}>
        {data?.map((item, i) => {
            if (!item) return null;
            const { pic_path, id, sold, title: titleMap, priceWithRate, price, discount } = item;
            const image = pic_path.replace(/http:\/\/g.search[1-3]?.alicdn.com/g, 'https://g-search3.alicdn.com');
            const productTitle = titleMap;
            const discountPrice = priceWithRate || price;
            const originalPrice = priceWithRate ? price : null;
            return <React.Fragment key={i}>
                <Card data={{ image, id, sold, productTitle, discountPrice, originalPrice, discount }} />
            </React.Fragment>
        })}
    </Masonry>
}

export default React.memo(MasonryContainer);
