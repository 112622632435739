import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    refresh: false,
}

const refreshReducer = createSlice({
    name: 'refreshReducer',
    initialState,
    reducers: {
        refreshAC(state, action) {
            state.refresh = action.payload
        }
    }
})

export const {
    refreshAC,
} = refreshReducer.actions
export default refreshReducer.reducer