import React, { useEffect, useState } from 'react';
import style from "./Review.module.scss";
import { Modal } from '../index';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from 'react-redux';
import { dataFormatter } from '@utils/NFormatter';
import { getTranslateApi } from '@http/Main/Translate';

const Review = ({ data }) => {
    let content;

    if (data.rateList?.length > 0) {
        content = <div className={style.review}>
            {data.rateList.map((el, k) => <div key={k}>
                <div>
                    <div>
                        <p>{el.userName}</p>
                        <div>
                            <p>{dataFormatter(el.dateTime)}</p>
                            {/* <div><img src={`https://tao38.ru/images/ratings/${el.memberLevel}.gif`} alt={el.skuInfo} /></div> */}
                        </div>
                    </div>
                    <span>
                        {el.content}
                    </span>
                    {el.images && <div className={style.gallery}>
                        <Modal data={{
                            galleryID: "gallery" + uuidv4(),
                            props: el.images
                        }} />
                    </div>}
                </div>
            </div>)}
        </div>
    } else {
        content = <div className={style.review__null}>
            <h3>Об этом товаре отзывов пока нет</h3>
            <p>Попробуйте товар и поделитесь своим мнением!</p>
        </div>
    }

    return content
}

export default Review;
