import React, { useEffect, useState } from 'react';
import style from "./Home.module.scss";
import { Banner } from './index';
import withSizes from 'react-sizes';
import randomColor from "randomcolor";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getHomeCategoryApi } from '@http/Main/Home/TopList';
import { TitleCard, SliderContainer, Loading } from '@components';

const Home = ({ tablet }) => {
    const dispatch = useDispatch();
    const { homeList } = useSelector(state => state.home)
    const [color, setColor] = useState([])

    useEffect(() => {
        const controller = new AbortController();
        setColor(colorGenerate())
        if (homeList.length === 0)
            ['5118', '5119', '5120', '5121', '5125'].map(el => dispatch(getHomeCategoryApi(el, 1)));
        return () => {
            controller.abort("abort")
        }
    }, [])

    const textList = [
        { cats: 5118, text: "Женская одежда" },
        { cats: 5119, text: "Мужская одежда" },
        { cats: 5120, text: "Женский обувь" },
        { cats: 5121, text: "Мужской обувь" },
        { cats: 5125, text: "Аксессуары" },
    ]

    const colorGenerate = () => Array(3).fill().map((el) => randomColor({ format: 'rgba', alpha: 0.3 }))

    return <div className={style.main}>
        <div className={style.banner} style={{ marginBottom: tablet ? "0px" : "44px" }}>
            <div>
                <Banner />
            </div>
            {!tablet && <div>
                {Array(2).fill().map((el, i) => <Link style={{ background: color[i] }} key={i} to="/">
                    <div>
                        <div>
                            <div>Выгодные предложения</div>
                            <div>Акция недели</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>
                                <div>
                                    <img loading="lazy" alt="" src="https://img.joomcdn.net/1eaf9d391c87f76b0c99f50ce44847803c414d99_352_352.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>)}
            </div>}
        </div>
        <div className={style.top_list}>
            {homeList.length > 0 ? <>
                {homeList.map((el, key) => <TitleCard key={key} name={textList.find(res => res.cats === parseInt(el.catId))?.text}>
                    <SliderContainer data={el.items.map(val => ({
                        title: val.title,
                        img: val.itemImg,
                        itemId: val.id,
                        price: parseFloat(val.salePrice),
                        sold30Days: val.boughtTxt,
                        rate: null,
                        reservePrice: parseFloat(val.originalPrice)
                    }))} />
                </TitleCard>)}
            </> : <Loading />}
        </div>
    </div>
}
const mapSizesToProps = ({ width }) => ({
    isMobile: width < 769,
    tablet: width < 991,
})
export default withSizes(mapSizesToProps)(Home);
