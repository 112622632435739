export const nFormatter = (num, digits) => {
  var si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
};

export const http_build_query = (payload) => {
  var params = new URLSearchParams();
  for (var key in payload) {
    params.append(key, payload[key]);
  }
  return params.toString()
}

export const dataFormatter = (data) => {
  let d = new Date(`2022-04-02`);
  let ye = new Intl.DateTimeFormat('ru', { year: 'numeric' }).format(d);
  let mo = new Intl.DateTimeFormat('ru', { month: 'long' }).format(d);
  let da = new Intl.DateTimeFormat('ru', { day: '2-digit' }).format(d);
  return `${da} ${mo} ${ye}`;
}