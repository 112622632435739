import axios from "axios";

const $host = axios.create({
    baseURL: process.env.REACT_APP_API_URL
})

const $authHost = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
})

$authHost.interceptors.request.use((config) => {
    config.headers['x-access-token'] = `${JSON.parse(localStorage.getItem('about')).token}`
    return config
})

$authHost.interceptors.response.use((config) => {
    return config;
}, async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && error.config && !error.config._isRetry) {
        originalRequest._isRetry = true;
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}auth/refresh-token`, { token: JSON.parse(localStorage.getItem('about')).token })
            let newToken = {
                ...JSON.parse(localStorage.getItem("about")),
                token: response.data.token
            }
            localStorage.setItem('about', JSON.stringify(newToken));
            return $authHost.request(originalRequest);
        } catch (e) {
            console.log('НЕ АВТОРИЗОВАН')
        }
    }
    throw error;
})

export { $authHost, $host }