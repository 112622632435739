import { $host } from "@http"
import { toast } from "react-toastify";
import { loginAC } from "@store/Reducers/Header/Sign";

export const postSignUpApi = (payload) => async dispatch => {
    try {
        const { data } = await $host.post(`auth/signup`, payload)
        if (data.hasOwnProperty("email")) {
            const { token } = data
            // localStorage.setItem('jwtToken', token)
            localStorage.setItem('about', JSON.stringify(data))
            dispatch(loginAC(data))
        } else {
            toast.error(data)
        }
    } catch (e) {
        console.log(e.message)
    }
};

export const postSignInApi = (payload) => async dispatch => {
    try {
        const { data } = await $host.post(`auth/signin`, payload)
        if (data.hasOwnProperty("email")) {
            const { token } = data
            // localStorage.setItem('jwtToken', token)
            localStorage.setItem('about', JSON.stringify(data))
            dispatch(loginAC(data))
        } else {
            toast.error(data)
        }
    } catch (e) {
        console.log(e.message)
    }
};