import React, { useState, useEffect } from 'react';
import style from "./Slider.module.scss";
import "swiper/css/pagination";
import withSizes from "react-sizes";
import { Skeleton } from '@components';
import "@fancyapps/ui/dist/fancybox.css";
import { useSelector } from 'react-redux';
import { BsPlayFill } from "react-icons/bs";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Thumbs, Pagination } from "swiper";
import { Fancybox as NativeFancybox } from "@fancyapps/ui/dist/fancybox.esm.js";

const Slider = ({ data, isMobile }) => {
    const paginationRef = React.useRef(null)
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    let { configSku } = useSelector(state => state.product)

    const delegate = `[data-fancybox="gallery"]`;

    useEffect(() => {
        const opts = {};
        NativeFancybox.bind(delegate, opts);
        return () => {
            NativeFancybox.close(true);
        };
    }, []);

    useEffect(() => {
        if (thumbsSwiper !== null) setThumbsSwiper(null)
    }, [data])

    let content;
    if (data === null) {
        content = <div className={style.skeleton}>
            <Skeleton quantity={1} appClassName={style.skeleton__slid} />
            <Skeleton quantity={4} appClassName={style.skeleton__items} />
        </div>
    } else {
        content = <div className={style.main}>
            <div className={style.product__gallery}>
                <div>
                    <div className={style.swiper_top_gallery_block}>
                        <Swiper
                            // style={{
                            //     "--swiper-navigation-color": "#fff",
                            //     "--swiper-pagination-color": "#fff",
                            // }}
                            // navigation={true}
                            pagination={{
                                el: paginationRef.current
                            }}
                            slidesPerView="auto"
                            spaceBetween={0}
                            thumbs={{ swiper: thumbsSwiper }}
                            modules={[FreeMode, Thumbs, Pagination]}
                        >
                            {data.map((el, i) => <SwiperSlide key={i}>
                                <a
                                    href={configSku?.imgUrl && i == 0 ? configSku.imgUrl : typeof el === "string" ? el : el[0].videoUrl}
                                    key={i}
                                    data-fancybox='gallery'
                                >
                                    {typeof el === "string" && <img src={configSku?.imgUrl && i == 0 ? configSku.imgUrl + "_400x400.jpg" : el + "_400x400.jpg"} />}
                                    {typeof el === "object" && <>
                                        <video poster={el[0].coverUrl + "_400x400.jpg"}>
                                            <source src={el[0].videoUrl} />
                                        </video>
                                        <BsPlayFill size={70} />
                                    </>}
                                </a>
                            </SwiperSlide>)}
                            <div className={style.paginationCustom} ref={paginationRef} />
                        </Swiper>
                    </div>

                    <div className={style.swiper_gallery_block}>
                        <Swiper
                            onSwiper={setThumbsSwiper}
                            spaceBetween='8'
                            slidesPerView='auto'
                            freeMode={true}
                            watchSlidesProgress={true}
                            modules={[FreeMode, Thumbs]}
                        >
                            {data.map((el, i) => <SwiperSlide key={i}>
                                {typeof el === "string" && <img src={configSku?.imgUrl && i == 0 ? configSku.imgUrl + "_100x100.jpg" : el + "_100x100.jpg"} />}
                                {typeof el === "object" && <img src={el[0].coverUrl + "_100x100.jpg"} />}
                            </SwiperSlide>)}
                        </Swiper>
                    </div>
                </div>
            </div>
        </div>
    }

    return content
}

const mapSizesToProps = ({ width }) => ({
    isMobile: width < 768,
    tablet: width < 992,
})

export default React.memo(withSizes(mapSizesToProps)(Slider));
