import CryptoJS from 'crypto-js';

const secretKey = process.env.REACT_APP_API_SECRET;

export const generateSignature = (endpoint, timestamp, method, body) => {
    const message = `${endpoint}:${timestamp}:${method}:${body}`;
    const hash = CryptoJS.HmacSHA256(message, secretKey);
    const signature = CryptoJS.enc.Base64.stringify(hash);
    return encodeURIComponent(signature);
}

export const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
            resolve(fileReader.result);
        };

        fileReader.onerror = (error) => {
            reject(error);
        };
    });
};

export const compressAndConvertToBase64 = (file, quality) => {
    return new Promise((resolve, reject) => {
        // Создаем новый FileReader
        const reader = new FileReader();

        // Устанавливаем обработчик события onload
        reader.onload = () => {
            // Создаем новый объект изображения
            const img = new Image();

            // Устанавливаем обработчик события onload
            img.onload = () => {
                // Создаем элемент canvas
                const canvas = document.createElement('canvas');

                // Устанавливаем размеры canvas, чтобы соответствовать изображению
                canvas.width = img.width;
                canvas.height = img.height;

                // Рисуем изображение на canvas
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0);

                // Получаем строку base64 из canvas
                const base64 = canvas.toDataURL('image/jpeg', quality);

                // Разрешаем обещание со строкой base64
                resolve(base64);
            };

            // Устанавливаем источник изображения на данные файла
            img.src = reader.result;
        };

        // Читаем файл как URL-адрес данных
        reader.readAsDataURL(file);
    });
}