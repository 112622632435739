import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
    request: false,
    items: [],
    listHeader: null,
    pagination: null,
    pageLoading: false,
}

const searchGlobalReducer = createSlice({
    name: 'searchGlobalReducer',
    initialState,
    reducers: {
        requestAC(state, action) {
            state.request = action.payload
        },
        itemsAC(state, action) {
            state.items = action.payload
        },
        listHeaderAC(state, action) {
            state.listHeader = action.payload
        },
        paginationAC(state, action) {
            state.pagination = action.payload
        },
        pageAC(state, action) {
            state.items.push(...action.payload)
        },
        pageLoadingAC(state, action) {
            state.pageLoading = action.payload
        }
    }
})

export const {
    requestAC,
    itemsAC,
    listHeaderAC,
    paginationAC,
    pageAC,
    pageLoadingAC
} = searchGlobalReducer.actions
export default searchGlobalReducer.reducer