import React, { useState, useEffect } from 'react';
import style from "./List.module.scss"
import { Crum } from "@components";
import { Loading } from '@components';
import randomColor from "randomcolor";
import { useQuery } from 'react-query';
import { Link } from "react-router-dom";
import { getListApi } from '@http/Main/Catalog';
import { BiChevronDown, BiChevronUp } from "react-icons/bi";


const Catalog = () => {
    const [toggle, setToggle] = useState([])
    const [color, setColor] = useState([])

    const { data: cats, isFetching } = useQuery(["cats"], getListApi(), { refetchOnWindowFocus: false });

    const handlerToggle = (idEvent) => setToggle((prevItems) => ({ ...prevItems, [idEvent]: !prevItems[idEvent] }));

    useEffect(() => {
        if (!isFetching) setColor(colorGenerate())
    }, [isFetching])

    if (isFetching) return <Loading />

    let data = JSON.parse(cats);
    const colorGenerate = () => data?.cats?.map((el) => randomColor({ format: 'rgba', alpha: 0.3 }))
    let crum = [{ title: "Главное", link: "/" }, { title: "Каталог", link: "/cat" }]

    return <div className={style.main}>
        <aside>
            {data.cats.slice(0, 50).map((el, i) => <div key={i}>
                <div>
                    <div className={style.pathRoot}>
                        <Link className={style.elColor} to={{ pathname: '/list', search: `?id=${el.id}` }}>
                            {el.name}
                        </Link>
                        <button onClick={() => handlerToggle(i)}>{toggle[i] ? <BiChevronUp size="32" /> : <BiChevronDown size="32" />}</button>
                    </div>
                    {toggle[i] && <div className={style.pathСhild}>
                        {el.path?.map((child, k) => <Link key={k}
                            className={style.elColor}
                            to={{ pathname: '/list', search: `?id=${child.id}` }}>{child.name}</Link>)}
                    </div>}
                </div>
            </div>)}
        </aside>
        <div>
            <Crum data={crum} />
            <div>
                {data.cats.map((el, i) => <div key={i}>
                    <div>
                        <Link to={{ pathname: '/list', search: `?id=${el.id}` }}>
                            <div style={{ backgroundColor: `${color[i]}` }}>
                                <img src={el.image} alt="" />
                                <div>{el.name}</div>
                            </div>
                        </Link>
                    </div>
                </div>)}
            </div>
        </div>
    </div>
}

export default Catalog;
