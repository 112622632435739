import { useEffect } from 'react';

export function useYatranslate() {
    useEffect(() => {
        const yatranslate = {
            lang: "zh",
            langFirstVisit: 'ru',
        };

        if (yatranslate.langFirstVisit && !localStorage.getItem('yt-widget')) {
            yaTranslateSetLang(yatranslate.langFirstVisit);
        }

        const script = document.createElement('script');
        script.src = `https://translate.yandex.net/website-widget/v1/widget.js?widgetId=ytWidget&pageLang=${yatranslate.lang}&widgetTheme=light&autoMode=false`;
        document.getElementsByTagName('head')[0].appendChild(script);

    }, []);

    const yaTranslateSetLang = (lang) => {
        localStorage.setItem('yt-widget', JSON.stringify({
            "lang": lang,
            "active": true
        }));
    }
}