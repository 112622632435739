import React from 'react';
import style from "./Slider.module.scss";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { SliderLField, Button } from "@components";
import { TbCurrencyPaanga } from "react-icons/tb";
import { FcCurrencyExchange } from "react-icons/fc";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup.object().shape({
    from: yup.number().required("Обязательно"),
    to: yup.number().required("Обязательно")
});

export const Slider = ({ handlerSlider }) => {
    const {
        register,
        control,
        formState: { errors },
        handleSubmit,
    } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const handlerSubmit = (event) => {
        handlerSlider(event)
    }

    return <div className={style.main}>
        <div>
            <div><FcCurrencyExchange size={20} /></div>
            <div>Цена</div>
        </div>
        <div className={style.input}>
            <div>
                <SliderLField
                    register={register}
                    errors={errors}
                    name="from"
                    from="ОТ"
                    to={<TbCurrencyPaanga size={18} />}
                />
            </div>
            <div>
                <SliderLField
                    register={register}
                    errors={errors}
                    name="to"
                    from="ОТ"
                    to={<TbCurrencyPaanga size={18} />}
                />
            </div>
        </div>
        <div className={style.button}>
            <Button
                onClick={handleSubmit(handlerSubmit)}>
                <span>искать</span>
            </Button>
        </div>
    </div>
}
