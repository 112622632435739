import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    homeList: [],
}

const homeReducer = createSlice({
    name: 'homeReducer',
    initialState,
    reducers: {
        homeListAC(state, action) {
            state.homeList.push(action.payload)
        },
    }
})

export const {
    homeListAC,
} = homeReducer.actions
export default homeReducer.reducer