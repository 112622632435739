import React, { useEffect, useState } from 'react';
import style from "./Detail.module.scss";
import withSizes from 'react-sizes';
import { useQuery } from 'react-query';
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { configSkuAC } from "@store/Reducers/Main/Product"
import { getItemRecommendBottomApi, getItemWrldApi } from '@http/Main/Product';
import { SliderContainer, Modal, TitleCard, Button, Refresh, Loading, Crum } from '@components';
import { Slider, Info, GroupProps, Seller, Support, Form, Tabs } from "./Components/index";

const Detail = ({ tablet }) => {
    const dispatch = useDispatch();
    const [isModal, setIsModal] = useState(false);
    const [showData, setShowData] = useState(null);
    const [searchParams] = useSearchParams();
    const { id } = Object.fromEntries([...searchParams]);

    const { configSku } = useSelector(state => state.product);

    const { data, isLoading, refetch, isFetching } = useQuery(
        [id],
        getItemWrldApi(id),
        { refetchOnWindowFocus: false }
    );

    const { data: recommend, refetch: refetchRecommend } = useQuery(
        [showData],
        getItemRecommendBottomApi({ id, seller: showData }),
        { refetchOnWindowFocus: false, enabled: false }
    );

    const isDataReady = !!data?.seller?.userId;

    useEffect(() => {
        if (isDataReady) {
            setShowData(data.seller.userId);
        }
        return () => {
            dispatch(configSkuAC(null));
            setShowData(null);
        };
    }, [data?.seller?.userId]);

    useEffect(() => {
        if (isDataReady && showData) {
            refetchRecommend();
        }
    }, [showData]);

    if (isLoading) {
        return <Loading />;
    }

    if (!data?.title) {
        return <Refresh reFetch={refetch} isFetching={isFetching} />;
    }

    const { seller, rate } = data.pageInitialProps?.httpData.normalItemResponse || data.appData?.httpData.normalItemResponse || {};
    const {
        sellerNick,
        title,
        images,
        itemPrice,
        totalCount,
        soldQuantityTotal,
        itemSkuDO,
        videos,
        crum
    } = data;

    const { httpData, pageData } = data?.pageInitialProps || data?.appData || {};
    const { price } = configSku || {
        price: { price: parseFloat(httpData?.normalItemResponse?.itemPrice?.promotionPrice) },
    };
    const originalPrice = parseFloat(httpData?.normalItemResponse?.itemPrice?.originalPrice);
    const promotionPrice = parseFloat(httpData?.normalItemResponse?.itemPrice?.promotionPrice);
    const priceDifference = Math.round(promotionPrice * 100 / originalPrice - 100);
    const isPricePromotion = originalPrice > promotionPrice;
    const isPriceMoreThanConfig = originalPrice > price.price;
    const isButtonDisabled = !!(data?.itemSkuDO.skuPropertyList?.length && !configSku);

    const info = {
        title,
        sku: itemSkuDO,
        creditLevel: seller.creditLevel,
        price: itemPrice,
        rate: totalCount,
        goodRatePercentage: soldQuantityTotal,
        quantity: {
            quantity: 200,
            moreQuantity: true,
        },
    };

    const recommendItem = data.RecommendItem
        ? data.RecommendItem.map(el => ({
            title: el.name,
            img: el.picUrl.replace(/http:\/\/g.search[1-3]?.alicdn.com/g, "https://g-search3.alicdn.com"),
            itemId: el.itemId,
            price: el.salePrice,
            reservePrice: el.reservePrice,
            sold30Days: el.sold30Days,
            rate: null,
        }))
        : null;

    const looked = recommend ? recommend?.result[0]?.itemList.map(el => ({
        title: el.title,
        img: el.img.replace(/http:\/\/g.search[1-3]?.alicdn.com/g, 'https://g-search3.alicdn.com'),
        itemId: el.itemId,
        price: el.price,
        sold30Days: el.sold,
        rate: null,
        reservePrice: el.reservePrice
    })) : null

    const similar = recommend ? recommend.result[1]?.itemList.map(el => ({
        title: el.title,
        img: el.img.replace(/http:\/\/g.search[1-3]?.alicdn.com/g, 'https://g-search3.alicdn.com'),
        itemId: el.itemId,
        price: el.price,
        sold30Days: el.sold,
        rate: null,
        reservePrice: el.reservePrice
    })) : null

    const formData = {
        item: pageData.itemId,
        seller: sellerNick,
        title: title,
        level: seller.creditLevel,
        img: images[0],
        quantity: 1,
        config:
            itemSkuDO?.skuPropertyList.length > 0
                ? configSku
                : {
                    price: {
                        price: parseFloat(httpData.normalItemResponse.itemPrice.promotionPrice),
                    },
                },
    };

    return <div className={style.main}>
        <div>
            <Crum data={crum ? [...[{ title: "Главное", link: "/" }, { title: "Каталог", link: "/cat" }], ...crum?.map(el => ({ title: el.name, link: `/list?id=${el.id}` }))] : [{ title: "Главное", link: "/" }, { title: "Каталог", link: "/cat" }]} />
        </div>
        <div className={style.full_width_section}>
            <div>
                <Slider data={data.images} />
                <Info isModal={() => setIsModal(!isModal)} data={info} crum={crum} />
            </div>
            <div>{recommendItem && <div>
                <TitleCard name="Рекомендованный">
                    <SliderContainer data={recommendItem} />
                </TitleCard>
            </div>}
            </div>
        </div>
        <div className={style.group__props}>
            <TitleCard name="Основная информация">
                <GroupProps data={data} />
            </TitleCard>
        </div>
        <div className={style.recommend_bottom}>
            {looked && <div>
                <TitleCard name='Так же смотрят'>
                    <SliderContainer data={looked} />
                </TitleCard>
                {similar && <TitleCard name='С этим товаром покупают'>
                    <SliderContainer data={similar} />
                </TitleCard>}
            </div>}
            <div className={style.ratingsAndReviews}>
                <div className={style.seller}>
                    <Seller data={seller} />
                </div>
                <div className={style.review}>
                    <Tabs rate={rate} video={videos} id={id} />
                </div>
                <div className={style.call}>
                    <Support />
                </div>
            </div>
        </div>
        {tablet && <div className={style.stiky}>
            <div>
                <div className={isPricePromotion ? style.old : ''}>
                    <div className={style.price_new}>
                        {configSku ? configSku?.price.price : itemPrice.promotionPrice}СМН
                    </div>
                    {isPriceMoreThanConfig && <div className={style.price_old_wrapper}>
                        <div className={style.price_old}>
                            {itemPrice.originalPrice} СМН
                        </div>
                        <div className={style.price_additional}>
                            <div>
                                <span>{priceDifference}%</span>
                            </div>
                        </div>
                    </div>}
                </div>
                <div>
                    <div>
                        <Button
                            disabled={isButtonDisabled}
                            style={{ opacity: isButtonDisabled ? "0.7" : "1" }}
                            onClick={() => setIsModal(true)}
                        >В корзину</Button>
                    </div>
                </div>
            </div>
        </div>}
        {isModal && <Modal isImage={true} title="Выберите адрес или магазин самовывоза" onToggle={() => setIsModal(false)}>
            <Form isModal={setIsModal} data={formData} />
        </Modal>}
    </div>
}

const mapSizesToProps = ({ width }) => ({
    isMobile: width < 769,
    tablet: width < 991,
})
export default withSizes(mapSizesToProps)(Detail);

