import React, { useEffect, useState } from "react";
import { CgChevronDown } from "react-icons/cg";
import style from "./Select.module.scss";

export const Select = ({
    appClassName,
    placeholder,
    data,
    onSelect,
    name,
    value,
    error
}) => {
    const [isToggle, setIsToggle] = useState(false);
    const [title, setTitle] = useState("");
    let isMounted = true;

    useEffect(() => {
        if (value) {
            setTitle(value);
        }
        return () => null;
    }, [value]);

    const handlerCheck = (id, name, value) => {
        onSelect(id, value, name);
        setTitle(value);
    };

    useEffect(() => {
        window.addEventListener("click", (e) => {
            if (!e.target.matches(`.${name}`)) {
                if (isMounted) setIsToggle(false);
            }
        });
        return () => {
            isMounted = false
        }
    }, []);

    return (
        <div
            className={`${style.main} ${appClassName}`}
        >
            <button
                style={{ pointerEvents: !data ? "none" : "auto", borderColor: error && '#FF3333' }}
                onClick={() => setIsToggle((prev) => !prev)}
                className={`${name} ${style.fieldblock} ${isToggle ? "fieldSelectActiveToggle" : ""}`}
            >
                <p className={`${name} ${style.fieldblock} ${isToggle ? "fieldSelectActiveToggle" : ""}`}>{placeholder ? placeholder : title}</p>
                <CgChevronDown className={`${name}`} />
            </button>
            {isToggle && (
                <span className={style.selected}>
                    {data.map((el, idx) => (
                        <div
                            key={idx + 1}
                            onClick={() => handlerCheck(el.id, name, el.name)}
                            className={el.name === title ? style.fieldSelectActive : ""}
                        >
                            {el.name}
                        </div>
                    ))}
                </span>)}
            {error && <p>error</p>}
        </div>
    );
};
