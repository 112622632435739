import React from 'react';
import style from './In.module.scss';
import { LField, LFieldMask, SelectField } from '@components';

export const In = ({
    getValues,
    errors,
    register,
}) => {
    return <div className={style.main}>
        <div>
            <LField
                id="inEmail"
                name="inEmail"
                type="text"
                label="E-mail"
                register={register}
                errors={errors} />
        </div>
        <div>
            <LField
                id="inPassword"
                name="inPassword"
                type="text"
                label="Пароль"
                register={register}
                errors={errors} />
        </div>
    </div>
}
