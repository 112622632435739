import React from 'react';
import style from "./Footer.module.scss";
import { Link } from "react-router-dom";

const Footer = () => {
    return <footer className={style.main}>
        <div className='container'>
            <div>
                <div>
                    <div>КЛУБ ТАЧМАРКЕТ</div>
                    <div>
                        <ul>
                            <li>
                                <Link to={{
                                    pathname: '/',
                                    search: `?id=1`,
                                }}>
                                    Вопросы и ответы
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div>
                    <div>О КОМПАНИИ</div>
                    <div>
                        <ul>
                            <li>
                                <Link to={{
                                    pathname: '/',
                                    search: `?id=1`,
                                }}>
                                    Вопросы и ответы
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div>
                    <div>КОНТАКТЫ</div>
                    <div>
                        <ul>
                            <li>
                                <Link to={{
                                    pathname: '/',
                                    search: `?id=1`,
                                }}>
                                    Вопросы и ответы
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div>
                    <div>МЫ В СОЦИАЛЬНЫХ СЕТЯХ</div>
                    <div>
                        <ul>
                            <li>
                                <Link to={{
                                    pathname: '/',
                                    search: `?id=1`,
                                }}>
                                    Вопросы и ответы
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div>
                <div></div>
                <div>©{(new Date()).getFullYear()}. Официальный сайт сети «Тачмаркет»</div>
            </div>
        </div>
    </footer>
}

export default Footer;
