import React, { useState } from 'react';
import style from "./OrderCard.module.scss";
import { ru } from 'date-fns/locale';
import { format } from 'date-fns'
import { Loading } from '@components';
import { Link } from "react-router-dom";
import { BiChevronDown } from "react-icons/bi";
import { MdDirectionsBike } from "react-icons/md";
import { FcCancel, FcInfo } from "react-icons/fc";


export const OrderCard = ({ data }) => {
    const [idList, setIdList] = useState(null);

    const list = (id) => {
        if (idList && idList === id) {
            setIdList(null)
        } else {
            setIdList(id)
        }
    }

    let content;
    if (data === null) {
        content = <Loading />
    } else if (data.length > 0) {
        content = <>
            {data.map((el, i) => <div className={style.card} key={i}>
                <div>
                    <div>
                        <div>
                            <div className={style.orderNumber} onClick={() => { list(el.id) }}>
                                <div className={style.header}>
                                    <div>
                                        <div style={{ backgroundColor: el.status === "Ожидания" && "rgb(144, 202, 249)" }}>
                                            {/* <FcCancel size={24} /> */}
                                            {el.status === "Ожидания" && <FcInfo size={24} />}
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <h3>Заказ на {JSON.parse(el.data).config.price.price} СМН {el.status}</h3>
                                            <div>{format(parseFloat(el.date_time), 'dd MMMM в HH:MM', { locale: ru })}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className={style.transaction}></div>
                                <BiChevronDown className={style.icon} size={24} />
                            </div>
                            {idList && idList === el.id && <div className={style.moreProduct}>
                                <div>
                                    <div>
                                        <div className={style.productsTop}>
                                            <div className={style.productsName}>Название продукта</div>
                                            <div className={style.productsQuantity}>Кол-во</div>
                                            <div className={style.productsPrice}>Цена</div>
                                            <div className={style.productsMark}>Рейтинг товара</div>
                                        </div>
                                        <div className={style.productWrapper}>
                                            <div>
                                                <div>
                                                    <Link to={{
                                                        pathname: '/detail',
                                                        search: `?id=${JSON.parse(el.data).item}`
                                                    }}>
                                                        <img src={`${JSON.parse(el.data).config.imgUrl ? JSON.parse(el.data).config.imgUrl : JSON.parse(el.data).img}_50x50q90.jpg`} alt={JSON.parse(el.data).title} />
                                                    </Link>
                                                    <div>
                                                        <a
                                                            target="_blank"
                                                            href={`https://item.taobao.com/item.htm?id=${JSON.parse(el.data).item}&skuId=${JSON.parse(el.data).config.skuId}`}
                                                            className={style.title}
                                                        >
                                                            {JSON.parse(el.data).title}
                                                        </a>
                                                        {JSON.parse(el.data).config.props && <div className={style.config}>
                                                            {JSON.parse(el.data).config.props.map((val, k) => <p key={k}>
                                                                <span>{val.propName}: </span>
                                                                <span>{val.valueName}</span>
                                                            </p>)}
                                                        </div>}
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className={style.product__quantity}>
                                                        {JSON.parse(el.data).quantity} шт
                                                    </div>
                                                    <div className={style.product__price}>
                                                        <div>
                                                            <div>{JSON.parse(el.data).config.price.price} СМН</div>
                                                        </div>
                                                    </div>
                                                    <div className={style.product__rating}>
                                                        <div>
                                                            <img src={`https://tao38.ru/images/ratings/${JSON.parse(el.data).level}.gif`} alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <div>
                                                            <div className={style.title}>8 товаров</div>
                                                            <div className={style.line}></div>
                                                            <div className={style.price}>153 СМН</div>
                                                        </div>
                                                        <div>
                                                            <div className={style.title}>8 товаров</div>
                                                            <div className={style.line}></div>
                                                            <div className={style.price}>153 СМН</div>
                                                        </div>
                                                        <div>
                                                            <div className={style.title}>8 товаров</div>
                                                            <div className={style.line}></div>
                                                            <div className={style.price}>153 СМН</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <div>
                                                            <div>
                                                                <div>
                                                                    <MdDirectionsBike size={18} />
                                                                    <span>Доставка</span>
                                                                </div>
                                                                <div>
                                                                    <p className="transaction-delivery__paragraph">{el.city}, {el.customer}, {el.phone}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div></div>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>)
            }
        </>
    } else {
        content = <div>Товаров нет!</div>
    }
    return content
}
