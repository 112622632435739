import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    configSku: null,
}

const productReducer = createSlice({
    name: 'productReducer',
    initialState,
    reducers: {
        configSkuAC(state, action) {
            state.configSku = action.payload
        },
    }
})

export const {
    configSkuAC,
} = productReducer.actions
export default productReducer.reducer