import React, { useState, useEffect } from 'react';
import style from "./List.module.scss";
import { useQuery } from 'react-query';
import { Slider } from "./Component/index";
import { getListRootApi, getListItemsApi } from '@http/Main/List';
import { useSearchParams } from "react-router-dom";
import { http_build_query } from "@utils/NFormatter";
import { Loading, Crum, Select, MasonryContainer } from '@components';


const sort = [
    { id: "sales-des", name: "По заказам" },
    { id: "default", name: "По умолчанию" },
    { id: "credit-des", name: "Высокий рейтинг" },
    { id: "price-des", name: "Дороже" },
    { id: "price-asc", name: "Дешевле" }
]
const crum = [{ title: "Главное", link: "/" }, { title: "Каталог", link: "/cat" }]

const List = () => {
    const [searchParams] = useSearchParams();
    const { id } = Object.fromEntries([...searchParams]);

    const [content, setContent] = useState(null);
    const [payload, setPayload] = useState({ id, type: 'getListItemsApi' });
    const [idCats, setIdCats] = useState({ id });

    const {
        status: rootStatus,
        data: rootData,
        isFetching: rootIsFetching,
        refetch: rootRefetch
    } = useQuery([http_build_query(idCats)], getListRootApi(idCats), { refetchOnWindowFocus: false });

    const {
        data: newItem,
        isFetching: itemIsFetching,
        refetch: itemRefetch,
        status: itemStatus
    } = useQuery([http_build_query(payload)], getListItemsApi(payload), { refetchOnWindowFocus: false, enabled: false });

    useEffect(() => {
        if (rootStatus === 'success') {
            setContent(rootData);
        }
    }, [rootStatus, rootData]);

    useEffect(() => {
        if (Object.keys(payload).length > 2) {
            itemRefetch();
        }
    }, [payload, itemRefetch]);

    useEffect(() => {
        if (newItem && itemStatus === 'success') {
            setContent(prevContent => ({
                ...prevContent,
                items: [...prevContent.items, ...newItem.items]
            }));
        }
    }, [newItem, itemStatus]);

    const handlerSort = id => setIdCats(prev => ({
        ...prev,
        sort: id
    }));

    const handlerSlider = event => setIdCats(prev => ({
        ...prev,
        startPrice: event.from,
        endPrice: event.to
    }));

    const showNewItem = id => {
        setPayload(prevPayload => ({
            ...prevPayload,
            ...idCats,
            page: id
        }));

        setContent(prevContent => ({
            ...prevContent,
            page: {
                ...prevContent.page,
                nextNo: id + 1,
                pageNo: id
            }
        }));
    };

    if (rootIsFetching || !content) return <Loading />;

    return <div className={style.main}>
        <aside>
            <div>
                <div className={style.content__header__counter}>
                    <div>
                        <div>
                            <Select value={sort.find(el => el.id === idCats.sort)?.name || "По умолчанию"} name={"sortSelect"} data={sort} onSelect={(value) => handlerSort(value)} />
                        </div>
                    </div>
                </div>
            </div>
            <Slider handlerSlider={handlerSlider} />
        </aside>
        <div>
            <Crum data={crum} />
            {content.items ? <>
                <MasonryContainer block="search" data={content.items} />
                <div className={style.button}>
                    {itemIsFetching ? <Loading /> : <button
                        onClick={() => showNewItem(content.page.nextNo)}>
                        <span>Показать еще</span>
                    </button>}
                </div>
            </> : <div>Нечего не найдено!</div>}
        </div>
    </div>
}

export default List;
