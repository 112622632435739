
import { $host } from "@http";
import { http_build_query } from "@utils/NFormatter";


export const getSellerApi = (payload) => async dispatch => {
    try {
        const { data } = await $host.get(`seller?${http_build_query(payload)}`)
        if (!data.hasOwnProperty("error")) {
            return data;
        }
    } catch (e) {
        console.log(e.message)
    }
};

