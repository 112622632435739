import React from 'react';
import style from "./Support.module.scss";
import { GoInfo } from "react-icons/go"
import { BsFillTelephoneFill } from "react-icons/bs";

export const Support = () => <div className={style.main}>
    <div>
        <div>
            <div>
                <GoInfo size={24} />
                <p>Не смогли найти ответ на свой вопрос? Напишите в чат:</p>
            </div>
            <a href="tel:+992929090904">
                <BsFillTelephoneFill size={20} />
                <span>Написать в чат</span>
            </a>
        </div>
    </div>
</div>
