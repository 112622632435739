import React from 'react';
import "./Loading.scss";

export const Loading = () => <div className='main'>
    <div className="ellipsis" >
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div >
</div>
