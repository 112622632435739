export const skuList = (list) => {
    var a = {}
        , n = [],
        t = 'object';
    for (var o in list.forEach((function (e) {
        a[e.propId] = a[e.propId] ? a[e.propId] : []
        a[e.propId].push(e)
    })), a)
        n.push(a[o])
    // console.log("object" === t ? a : n)
    return a;
};