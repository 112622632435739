import { $host } from "@http"
import { generateSignature } from "@utils/Sign";
import { http_build_query } from "@utils/NFormatter";

export const getImageApi = (base64, payload) => async dispatch => {
    const endpoint = 'image';
    const timestamp = Date.now();
    const method = 'POST';
    const body = {
        file: base64
    };
    const sortedKeys = Object.keys(body).sort();
    const sortedData = sortedKeys.map(key => `${key}=${body[key]}`).join('&');
    // Генерируем подпись
    const signature = generateSignature(endpoint, timestamp, method, sortedData);
    try {
        const { data } = await $host.post(`image?sign=${signature}&timestamp=${timestamp}&${http_build_query(payload)}`, { data: JSON.stringify(body) })
        return data;
    } catch (e) {
        console.log(e.message)
    }
};