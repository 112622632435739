import { $host } from "@http"
import file from "@view/Main/Catalog/list.txt"

export const getCatApi = (id = '1') => async dispatch => {
    try {
        const { data } = await $host.get(`cat?id=${id}`)
        return data;
    } catch (e) {
        console.log(e.message)
    }
};

export const getListApi = () => async dispatch => {
    try {
        const response = await fetch(file);
        const data = await response.text();
        return data
    } catch (e) {
        console.log(e.message)
    }
};
