import React, { useEffect } from 'react';
import style from "./Modal.module.scss";
import "@fancyapps/ui/dist/fancybox.css";
import { Fancybox as NativeFancybox } from "@fancyapps/ui/dist/fancybox.esm.js";

const Modal = ({ data }) => {
    const delegate = `[data-fancybox="${data.galleryID}"]`;

    useEffect(() => {
        const opts = {};
        NativeFancybox.bind(delegate, opts);
        return () => {
            NativeFancybox.close(true);
        };
    }, []);

    return <div className={style.main}>
        {data.props.map((image, index) => (
            <a
                href={image + '_Q75.jpg_.webp'}
                key={index}
                data-fancybox={data.galleryID}
            >
                <div>
                    <div>
                        <div>
                            <img src={image + '_Q75.jpg_.webp'} alt="" />
                        </div>
                    </div>
                </div>
            </a>))}
    </div>
}

export default Modal;
