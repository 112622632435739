import React, { useState } from 'react';
import style from "./Tabs.module.scss";
import { Review } from "../index";
import { useQuery } from 'react-query';
import { getDescApi } from '@http/Main/Product';
import { Loading } from '@components';

const Tabs = ({ rate, video, id }) => {
    const [active, setActive] = useState(0);

    const { data, isLoading, refetch, isFetching } = useQuery(
        [id + "desc"],
        getDescApi(id),
        { refetchOnWindowFocus: false }
    );

    return <div className={style.main}>
        <div className={style.tabs}>{["Отзывы ", "О товаре", "Видео"].map((el, i) => <React.Fragment key={i}>
            <button onClick={() => setActive(i)} className={`${style.button} ${i == active ? style.active : ""} ${style.ftZPut}`}>
                <span>{el}</span>
            </button>
        </React.Fragment>)}</div>
        <div className={style.tabsContent}>
            <div className={`${active === 0 ? style.active : ""}`}><Review data={rate} /></div>
            <div className={`${style.desc} ${active === 1 ? style.active : ""}`}>
                {isFetching ? <Loading /> : <>{data ? <div dangerouslySetInnerHTML={{ __html: data.blocks.Description.html }} /> : <div>Информация не найден!</div>}</>}
            </div>
            <div className={`${style.video} ${active === 2 ? style.active : ""}`}>
                {video ? <div>
                    <video poster={video[0].coverUrl} controls>
                        <source src={video[0].videoUrl} />
                    </video>
                </div> : <div>Видео не найден!</div>}
            </div>
        </div>
    </div >
}

export default Tabs;
