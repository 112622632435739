import React, { useState, useEffect, useCallback } from 'react';
import style from "./Image.module.scss";
import { getImageApi } from '@http/Main/Image';
import { useDispatch } from 'react-redux';
import { useLocation } from "react-router-dom";
import { compressAndConvertToBase64 } from "@utils/Sign";
import { MasonryContainer, Crum, Select, Skeleton, Loading, Refresh } from '@components';

const Image = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const controller = new AbortController();
    const [item, setItem] = useState(null)
    const [base64, setBase64] = useState(null);
    const [payload, setPayload] = useState({ sort: "0" });
    const [isMounted, setIsMounted] = useState(true)

    const handlerSort = (type, id) => {
        setPayload(prev => ({ ...prev, [`${type}`]: id }))
    }

    let sortTextList = [{ id: "2", name: "По заказам" }, { id: "0", name: "По умолчанию" }, { id: "4", name: "Дороже" }, { id: "3", name: "Дешевле" }];

    const handleFileChange = useCallback((file) => {
        compressAndConvertToBase64(file, 0.5)
            .then((base64String) => {
                setBase64(base64String);
            });
    }, []);

    const handleBase64Change = useCallback((base64) => {
        setItem(null);
        setIsMounted(true)
        dispatch(getImageApi(base64.split(',')[1], payload))
            .then((res) => {
                if (isMounted) setItem(res);
            });
    }, [dispatch, payload]);

    useEffect(() => {
        setPayload((prev) => (prev.sort !== "0" ? { sort: "0" } : prev));
        if (location.state?.files?.length > 0) {
            handleFileChange(location.state.files[0]);
        }
    }, [location, handleFileChange]);


    useEffect(() => {
        if (base64) {
            handleBase64Change(base64);
        }
        return () => {
            controller.abort("abort")
        }
    }, [base64, handleBase64Change]);

    if (item && !item?.items) return <Refresh reFetch={() => handleBase64Change(base64)} isFetching={false} />

    if (item?.items.length === 0) return <div>нет товаров!</div>

    const navData = item?.NavModule.bboxesStr.split(";").map((el, key) => ({ id: el, name: `Объект ${key + 1}` }));
    const navValue = navData?.find(obj => obj.id === item.NavModule.region).name;
    const sortData = sortTextList;
    const sortValue = sortData.find(obj => obj.id === payload.sort).name;

    return <div className={style.main}>
        <div className={style.right}>
            <div>
                <div>
                    <img src={base64} alt="" />
                </div>
            </div>
            <div>
                {!item ? <Skeleton quantity={4} appClassName={style.skeleton__nav} /> : <>
                    {navData.length > 1 && (
                        <Select
                            value={navValue}
                            name={"NavModule"}
                            data={navData}
                            onSelect={(value) => handlerSort("nav", value)}
                        />
                    )}
                </>}
            </div>
        </div>
        <div className={style.left}>
            <Crum data={[{ title: "Главное", link: "/" }, { title: "Каталог", link: "/cat" }]} />
            <div className={style.sort}>
                <div>{!item ? <Skeleton quantity={1} appClassName={style.skeleton__total} /> : `${item.items.length} Товаров`}</div>
                <div>
                    <Select
                        value={sortValue}
                        name={"sortSelect"}
                        data={sortData}
                        onSelect={(value) => handlerSort("sort", value)}
                    />
                </div>
            </div>
            <div>{!item ? <Loading /> : <MasonryContainer block="search" data={item.items} />}</div>
        </div>
    </div>
}

export default Image;
