import React from 'react';
import style from './Up.module.scss';
import { LField, LFieldMask, SelectField } from '@components';

export const Up = ({
    getValues,
    errors,
    register,
}) => {
    return <div className={style.main}>
        <div>
            <LField
                id="upEmail"
                name="upEmail"
                type="text"
                label="Email"
                register={register}
                errors={errors} />
        </div>
        <div>
            <LFieldMask
                register={register}
                errors={errors}
                id="upPhone"
                name="upPhone"
                label="Ваш номер телефон"
                mask="+\9\92 (99) 999-99-99"
            />
        </div>
        <div>
            <LField
                id="upPassword"
                name="upPassword"
                type="text"
                label="Пароль"
                register={register}
                errors={errors} />
        </div>
    </div>
}

