import React, { useState, useEffect } from 'react';
import style from "./Header.module.scss";
import { Search, Sign } from "./index";
import withSizes from 'react-sizes'
import { TMenu, Modal } from '@components';
import { Link } from "react-router-dom"
import { BiMenu } from "react-icons/bi";
import { useSelector } from "react-redux";
import { TiLocation } from "react-icons/ti";
import { RiUser3Fill } from "react-icons/ri";
import { AiFillHeart } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { HiShoppingCart } from "react-icons/hi";
import { GiJugglingClubs } from "react-icons/gi"
import { MdDeliveryDining } from "react-icons/md"
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import Logo2 from "./../../assets/Header/5.png"
import { Logo } from "./../../assets/Header/tm"

const Header = ({ isMobile, isDelivery }) => {
    const [isModal, setIsModal] = useState(false)
    const about = JSON.parse(localStorage.getItem('about'))
    const [hideOnScroll, setHideOnScroll] = useState(true)

    let navigate = useNavigate();
    let { focus } = useSelector(state => state.search);
    let { login } = useSelector(state => state.sign);

    useEffect(() => {
        if (login !== null) {
            setIsModal(false);
        }
    }, [login]);

    useScrollPosition(
        ({ prevPos, currPos }) => {
            const isShow = currPos.y > prevPos.y
            if (isShow !== hideOnScroll) setHideOnScroll(isShow)
        },
        [hideOnScroll]
    )
    const userHandler = () => {
        if (about?.token) {
            return navigate("/profile");
        } else {
            setIsModal(true)
        }
    }

    return <header className={style.main}>
        <div className={`${style.CHeader} ${isMobile && !hideOnScroll ? style.hidden__CHeader : ""}`}>
            {focus && <div className={style.isSearch}></div>}
            <div className={`${style.HeaderMain} ${focus ? style.isFocus : ""}`}>
                <div className={`container ${style.container}`}>
                    <div className={style.logo}>
                        <div className={`${!isMobile ? `${focus || !hideOnScroll ? style.isFocusImg : ""}` : ""}`}>
                            <Logo />
                            {/* <img src="https://edonish.tj/static/media/edonish-new-logo%205.989a4bcf.svg" alt="" /> */}
                            <Link to="/">
                                <img src={Logo2} alt="логотип" />
                                {/* <img src={LogoTm} /> */}
                            </Link>
                        </div>
                    </div>
                    <div className={style.search}>
                        <div>
                            <Link to="/cat">
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" color="#FFFFFF">
                                    <path d="M5 6a1 1 0 011-1h3a1 1 0 011 1v3a1 1 0 01-1 1H6a1 1 0 01-1-1V6zm0 7a1 1 0 011-1h3a1 1 0 011 1v3a1 1 0 01-1 1H6a1 1 0 01-1-1v-3zm7-7a1 1 0 011-1h3a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1V6zm0 7a1 1 0 011-1h3a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-3z" fill="#FFFFFF"></path>
                                </svg>
                                <p>Каталог</p>
                            </Link>
                        </div>
                        {!isMobile && <Search placeholder="Поиск по каталогу" />}
                    </div>
                    {!isDelivery && <div className={style.delivery}>
                        <div className={style.deliveryIcon}>
                            <TiLocation />
                        </div>
                        <div className={style.deliveryContent}>
                            <p>Выберите адрес доставки или самовывоза</p>
                        </div>
                    </div>}
                    <div className={style.profile}>
                        {!isDelivery && <div>
                            <button>
                                <AiFillHeart size={20} />
                            </button>
                        </div>}
                        <div>
                            <button onClick={userHandler}>
                                <RiUser3Fill size={isMobile ? 17 : 20} />
                            </button>
                        </div>
                        <div>
                            <button>
                                <HiShoppingCart size={isMobile ? 17 : 18} />
                            </button>
                        </div>
                        <div className={style.menu}>
                            <BiMenu />
                        </div>
                    </div>
                </div>
            </div>
            {isMobile && <div className={style.isMobileSearch}>
                <div className={`container ${style.container}`}>
                    <Search placeholder="Поиск по каталогу" />
                </div>
            </div>
            }
            <div className={`${style.HeaderBottom} ${!isMobile ? `${!hideOnScroll ? style.HBHide : ""}` : ""}`}>
                <div className="container">
                    <div>
                        <TMenu list={["Акции", "Электронные каталоги", "Лучшие предложения", "Только для самовывоза", "Наши бренды", "Полезные продукты", "Свежая выпечка", "Итальянская кухня", "Сезонные товары"]} />
                    </div>
                    <div>
                        <div>
                            <Link to="/">
                                <GiJugglingClubs size={14} />
                                Вступить в клуб
                            </Link>
                        </div>
                        <div>
                            <Link to="/">
                                <MdDeliveryDining size={20} />
                                Доставка
                            </Link>
                        </div>
                        <div>
                            <Link to="/">
                                <TiLocation size={17} />
                                Магазины
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className={style.HOffset}></div>
        {isModal && <Modal isImage={false} appClassName={style.modal} title='Войти или зарегистрироваться' onToggle={() => setIsModal(false)}>
            <Sign />
        </Modal>}
    </header>
}

const mapSizesToProps = ({ width }) => ({
    isMobile: width < 992,
    isDelivery: width < 768,
})

export default withSizes(mapSizesToProps)(Header);
