import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import style from "./Product.module.scss";
import { skuList } from "@utils/Sku"
import withSizes from 'react-sizes';
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { SliderContainer, Button, Modal, Refresh, TitleCard } from '@components';
import { Slider, Info, GroupProps, Review, Seller, Support, Form } from "./Components/index";
import { getItemRecommendBottomApi, getItemWrldApi, getSuperBuyApi } from '@http/Main/Product';

const Product = ({ tablet }) => {
    const dispatch = useDispatch();
    const didMountRef = useRef(false);
    const controller = new AbortController();
    const [product, setProduct] = useState(null)
    const [productSku, setProductSku] = useState(null)
    const [isModal, setIsModal] = useState(false)
    const [recommendBottom, setRecommendBottom] = useState(null)
    let [searchParams, setSearchParams] = useSearchParams();
    let { id } = Object.fromEntries([...searchParams])
    let { configSku } = useSelector(state => state.product)
    let { refresh } = useSelector(state => state.refresh)

    useEffect(() => {
        let isMounted = true;
        if (product) {
            setProduct(null)
            setRecommendBottom(null)
            setProductSku(null)
        }
        dispatch(getItemWrldApi(id)).then(result => {
            // if (result.info?.pageInitialProps.httpData.normalItemResponse.item.videos !== undefined) result.info.pageInitialProps.httpData.normalItemResponse.item.images.push(result.info.pageInitialProps.httpData.normalItemResponse.item.videos)
            if (isMounted) setProduct(result)
        })
        dispatch(getSuperBuyApi(id)).then(result => {
            if (isMounted) setProductSku(result)
        })
        return () => {
            isMounted = false;
        }
    }, [searchParams])

    useEffect(() => {
        let isMounted = true;
        if (product !== null && product.info !== undefined) {
            dispatch(getItemRecommendBottomApi(id, product.info.pageInitialProps.httpData.normalItemResponse.seller.userId)).then(result => {
                if (isMounted) setRecommendBottom(result)
            })
        }
        return () => {
            controller.abort("abort")
            isMounted = false;
        }
    }, [product])

    useLayoutEffect(() => {
        if (didMountRef.current) {
            didMountRef.current = false;
            return;
        }
        searchParams.set("refresh", refresh);
        setSearchParams(searchParams)
    }, [refresh])

    return <div className={style.main}>
        {product === null || product.info !== undefined ? <>
            <div className={style.full_width_section}>
                <div>
                    <Slider data={product ? product.info.pageInitialProps.httpData.normalItemResponse.item.images : null} />
                    <Info isModal={() => setIsModal(true)} data={product && productSku ? {
                        title: productSku.data.goodsName,
                        skuBase: {
                            props: productSku.data.productProps ? skuList(productSku.data.productProps) : null,
                            skus: productSku.data.skuList,
                        },
                        creditLevel: product.info.pageInitialProps.httpData.normalItemResponse.seller.creditLevel,
                        price: product.info.pageInitialProps.httpData.normalItemResponse.itemPrice,
                        rate: product.info.pageInitialProps.httpData.normalItemResponse.rate.totalCount,
                        goodRatePercentage: product.info.pageInitialProps.httpData.normalItemResponse.itemTrade.soldQuantityTotal,
                        quantity: {
                            quantity: 200,
                            moreQuantity: true,
                        }
                    } : null} />
                </div>
                <div>
                    {product?.info.pageInitialProps.httpData.itemRecommendModuleResponse.tpp.items && <div>
                        <TitleCard name="Рекомендованный">
                            <SliderContainer data={product.info.pageInitialProps.httpData.itemRecommendModuleResponse.tpp.items.map(el => ({
                                title: el.name,
                                img: el.picUrl.replace(/http/g, 'https').replace(/http:\/\/g.search2.alicdn.com/g, 'https://g-search3.alicdn.com').replace(/http:\/\/g.search1.alicdn.com/g, 'https://g-search3.alicdn.com').replace(/http:\/\/g.search.alicdn.com/g, 'https://g-search3.alicdn.com'),
                                itemId: el.itemId,
                                price: el.salePrice,
                                reservePrice: el.reservePrice,
                                sold30Days: el.sold30Days,
                                rate: null
                            }))} />
                        </TitleCard>
                    </div>}
                </div>
            </div>
            {product && <div className={style.group__props}>
                <TitleCard name="Основная информация">
                    <GroupProps data={product} />
                </TitleCard>
            </div>}
            <div className={style.recommend_bottom}>
                {recommendBottom?.result.length > 0 && <div>
                    <TitleCard name='Так же смотрят'>
                        <SliderContainer data={recommendBottom.result[0].itemList.map(el => ({
                            title: el.title,
                            img: el.img.replace(/http/g, 'https').replace(/ http:\/\/g.search2.alicdn.com/g, 'https://g-search3.alicdn.com').replace(/http:\/\/g.search1.alicdn.com/g, 'https://g-search3.alicdn.com').replace(/http:\/\/g.search.alicdn.com/g, 'https://g-search3.alicdn.com'),
                            itemId: el.itemId,
                            price: el.price,
                            sold30Days: el.sold,
                            rate: null,
                            reservePrice: el.reservePrice
                        }))} />
                    </TitleCard>
                </div>}
                {product && <div className={style.ratingsAndReviews}>
                    <div className={style.seller}><Seller data={product.info.pageInitialProps.httpData.normalItemResponse.seller} /></div>
                    <div className={style.review}>
                        <div>
                            <div>
                                <Review data={product.info.pageInitialProps.httpData.normalItemResponse.rate} />
                            </div>
                        </div>
                    </div>
                    <div className={style.call}><Support /></div>
                </div>}
            </div>
            {product && tablet && <div className={style.stiky}>
                <div>
                    <div className={parseFloat(product.info.pageInitialProps.httpData.normalItemResponse.itemPrice.originalPrice) > parseFloat(product.info.pageInitialProps.httpData.normalItemResponse.itemPrice.promotionPrice) ? style.old : ''}>
                        <div className={style.price_new}>{configSku ? configSku?.price.price : parseFloat(product.info.pageInitialProps.httpData.normalItemResponse.itemPrice.promotionPrice)} СМН</div>
                        {configSku === null && parseFloat(product.info.pageInitialProps.httpData.normalItemResponse.itemPrice.originalPrice) > parseFloat(product.info.pageInitialProps.httpData.normalItemResponse.itemPrice.promotionPrice) && <div className={style.price_old_wrapper}>
                            <div className={style.price_old}>{parseFloat(product.info.pageInitialProps.httpData.normalItemResponse.itemPrice.originalPrice)}</div>
                            <div className={style.price_additional}>
                                <div>
                                    <span>{Math.round(parseFloat(product.info.pageInitialProps.httpData.normalItemResponse.itemPrice.promotionPrice) * 100 / parseFloat(product.info.pageInitialProps.httpData.normalItemResponse.itemPrice.originalPrice) - 100)}%</span>
                                </div>
                            </div>
                        </div>}
                        {parseFloat(product.info.pageInitialProps.httpData.normalItemResponse.itemPrice.originalPrice) > configSku?.price.price && <div className={style.price_old_wrapper}>
                            <div className={style.price_old}>{parseFloat(product.info.pageInitialProps.httpData.normalItemResponse.itemPrice.originalPrice)}</div>
                            <div className={style.price_additional}>
                                <div>
                                    <span>{Math.round(parseFloat(product.info.pageInitialProps.httpData.normalItemResponse.itemPrice.promotionPrice) * 100 / parseFloat(product.info.pageInitialProps.httpData.normalItemResponse.itemPrice.originalPrice) - 100)}%</span>
                                </div>
                            </div>
                        </div>}
                    </div>
                    <div>
                        <div>
                            <Button disabled={productSku?.data.skuList.length === 0 ? false : configSku ? false : true} style={{ opacity: productSku?.data.skuList.length === 0 ? "1" : configSku ? "1" : "0.7" }} onClick={() => { setIsModal(true) }}>В корзину</Button>
                        </div>
                    </div>
                </div>
            </div>}
            {isModal && <Modal isImage={true} title='Выберите адрес или магазин самовывоза' onToggle={() => setIsModal(false)}>
                <Form isModal={setIsModal} data={{
                    item: productSku.data.goodsId,
                    seller: product.info.pageInitialProps.httpData.normalItemResponse.seller.sellerNick,
                    seller: product.info.pageInitialProps.httpData.normalItemResponse.seller.sellerNick,
                    title: product.info.pageInitialProps.httpData.normalItemResponse.item.title,
                    level: product.info.pageInitialProps.httpData.normalItemResponse.seller.creditLevel,
                    img: product.info.pageInitialProps.httpData.normalItemResponse.item.images[0],
                    quantity: 1,
                    config: productSku?.data.skuList.length > 0 ? configSku : {
                        price: {
                            price: parseFloat(product.info.pageInitialProps.httpData.normalItemResponse.itemPrice.promotionPrice)
                        }
                    },
                }} />
            </Modal>}
        </> : <Refresh />}
    </div>
}

const mapSizesToProps = ({ width }) => ({
    isMobile: width < 769,
    tablet: width < 991,
})
export default withSizes(mapSizesToProps)(Product);

