import { $host } from "@http"
import { http_build_query } from "@utils/NFormatter";

export const getListRootApi = (payload) => async dispatch => {
    try {
        const { data } = await $host.get(`catIdRoot?${http_build_query(payload)}`)
        return data;
    } catch (e) {
        console.log(e.message)
    }
}

export const getListItemsApi = (payload) => async dispatch => {
    try {
        const { data } = await $host.get(`catId?${http_build_query(payload)}`)
        if (data.hasOwnProperty("items")) return data; else return null
    } catch (e) {
        console.log(e.message)
    }
}