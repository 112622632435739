import React from 'react';
import style from "./Seller.module.scss"
import { TitleCard } from "@components"
import withSizes from 'react-sizes';

const Seller = ({ data, isMobile }) => <div className={style.main}>
    <TitleCard appClassName={style.title} name="Информация о магазине">
        <div className={style.seller}>
            <div>
                <div>
                    <div>
                        <div>
                            <div>
                                <img src={data.shopIcon !== '' ? data.shopIcon + "_120x120.jpg" : ""} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <p>{data.sellerNick}</p>
            </div>
            <div>
                {data.evaluates.map((el, i) => <div key={i}>
                    <div>
                        {el.type === "post" && "Доставка"}
                        {el.type === "serv" && "Сервис"}
                        {el.type === "desc" && "Описания"}
                    </div>
                    <div>{el.score}</div>
                </div>)}
            </div>
        </div>
    </TitleCard>
</div>

const mapSizesToProps = ({ width }) => ({
    isMobile: width < 768,
    tablet: width < 992,
})

export default React.memo(withSizes(mapSizesToProps)(Seller));
