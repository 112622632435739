import React, { useState, useEffect } from 'react';
import style from './Info.module.scss';
import withSizes from 'react-sizes';
import { Helmet } from "react-helmet";
import { HiShoppingCart } from "react-icons/hi"
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { configSkuAC } from "@store/Reducers/Main/Product"
import { AiOutlineComment, AiFillHeart } from "react-icons/ai";
import { Button, Loading, Skeleton } from '@components';
import { getPriceItemApi } from '@http/Main/Product';


const Info = ({ data, tablet, isMobile, isModal }) => {
    const dispatch = useDispatch()
    const [selection, setSelection] = useState({})
    let [searchParams] = useSearchParams();
    let { id } = Object.fromEntries([...searchParams])
    let { configSku } = useSelector(state => state.product)

    const handlerSku = (pid, vid) => {
        setSelection(state => {
            const selection = {
                ...state.selection,
                [pid]: vid
            };

            if (vid === undefined) delete selection[pid];

            return {
                ...state,
                selection
            };
        })
    }

    const queryPropName = (skus, query) => {
        const result = []
        for (let pid in query) {
            const vid = query[pid]
            const sku = skus.find(sku => sku.pid === Number(pid))
            const property = sku.propertyValues.find(property => property.vid === vid)
            result.push(property.name)
        }
        return result
    }

    useEffect(() => {
        if (data !== null && data.sku.skuPropertyList.length > 0 && selection.selection && Object.keys(selection.selection).length === data.sku.skuPropertyList.length) {
            var propPath = Object.entries(selection.selection).map(arr => arr.join(":")).join(";")
            var skuId = data.sku.skuPVPairList.find(el => el.propPath === propPath)?.skuId

            let payload = {
                id: id,
                skuId: skuId
            }
            dispatch(configSkuAC(null))
            dispatch(getPriceItemApi(payload)).then(result => {
                let dataConfig = {
                    skuId: skuId,
                    price: {
                        price: result.totalcost.OriginalPrice,
                        marginprice: result.totalcost.marginprice
                    },
                    quantity: 200,
                    props: Object.keys(selection.selection).map((el, val) => ({
                        "propId": parseFloat(el),
                        "valueId": selection.selection[el],
                        "propName": Object.keys(selection.selection).map(list => data.sku.skuPropertyList.find(res => res.pid === parseInt(list)).name)[val],
                        "valueName": queryPropName(data.sku.skuPropertyList, selection.selection)[val]
                    }))
                }
                Object.keys(selection.selection).map(el => {
                    data.sku.skuPropertyList.map(res => {
                        res.propertyValues.find(list => {
                            if (list.vid === selection.selection[el] && list.image) {
                                dataConfig.imgUrl = list.image
                            }
                        })
                    })
                })
                dispatch(configSkuAC(dataConfig))
            })
        }
    }, [selection])

    if (tablet && data === null) return <Loading />

    return <div className={style.main}>
        <div className={style.title}>
            {<>
                <Helmet>
                    <title>TajMarket - {data.title}</title>
                </Helmet>
                <h1>{data.title}</h1>
            </>}
            <div>
                <div>
                    <div>
                        <div>
                            {/* <div>{data ? <img src={`https://tao38.ru/images/ratings/${data.creditLevel}.gif`} alt="" /> : <Skeleton quantity={1} appClassName={style.skeleton} />}</div> */}
                            {data ? <div>{data.goodRatePercentage} Продаж</div> : <Skeleton quantity={1} appClassName={style.skeleton} />}
                        </div>
                        <div>
                            <div><AiOutlineComment size={18} /></div>
                            {data ? <div>{data.rate} отзывов</div> : <Skeleton quantity={1} appClassName={style.skeleton} />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className={style.product_price_wrapper}>
            <div>
                <div className={style.product_price_wrapper__price}>
                    <div className={`${parseFloat(data?.price.originalPrice) > parseFloat(data?.price.promotionPrice) ? style.old : ''}`}>
                        {data ? <>
                            <div className={style.price_new}>{configSku ? parseFloat(configSku.price.price) : parseFloat(data?.price.promotionPrice)} СМН</div>
                            {configSku === null && parseFloat(data?.price.originalPrice) > parseFloat(data?.price.promotionPrice) && <div className={style.price_old_wrapper}>
                                <div>{parseFloat(data?.price.originalPrice)} СМН</div>
                            </div>}
                            {parseFloat(configSku?.price.price) < parseFloat(data?.price.originalPrice) && <div className={style.price_old_wrapper}>
                                <div>{parseFloat(data?.price.originalPrice)} СМН</div>
                            </div>}
                        </> : <Skeleton quantity={1} appClassName={style.skeleton} />}
                    </div>
                    {!isMobile && <div>
                        <div>
                            <Button disabled={data?.sku.skuPropertyList.length === 0 ? false : configSku ? false : true} style={{ opacity: data?.sku.skuPropertyList.length === 0 ? "1" : configSku ? "1" : "0.7" }} onClick={isModal} svg={<HiShoppingCart size={20} />}>
                                <span>В корзину</span>
                            </Button>
                        </div>
                        <Button appClassName={style.heart} svg={<AiFillHeart size={24} />} />
                    </div>}
                </div>
                <div className={style.price_card}>
                    <div>{data ? <>{configSku ? configSku.quantity : data.quantity.quantity}/шт</> : <Skeleton quantity={1} appClassName={style.skeleton} />}</div>
                    <div>{data ? <>
                        {configSku?.quantity > 10 && "В наличии много"}
                        {configSku?.quantity < 10 && "В наличии осталась мало"}
                        {configSku === undefined && "Нет в наличии"}
                        {configSku === null && "В наличии много"}
                    </> : <Skeleton quantity={1} appClassName={style.skeleton} />}</div>
                </div>
                <div className={style.sku}>
                    {data?.sku.skuPropertyList.length > 0 && <>
                        {data.sku.skuPropertyList.map((el, i) => <div key={i}>
                            <div>
                                <h2>{el.name}</h2>
                                {selection.selection && <div>
                                    {el.propertyValues.find(vidId => vidId.vid === selection.selection[el.pid])?.name}
                                </div>}
                            </div>
                            <ul>
                                {el.propertyValues.map((val, k) => <li onClick={() => handlerSku(el.pid, val.vid)} className={`${val.image ? style.image : ""} ${selection.selection && selection.selection[el.pid] === val.vid ? style.active : ""}`} style={{ background: val.image ? `url(${val.image}_50x50q90.jpg) center no-repeat` : "" }} key={k}>
                                    <span>{val.name}</span>
                                </li>)}
                            </ul>
                        </div>)}
                    </>}
                </div>
            </div>
        </div>
    </div >
}

const mapSizesToProps = ({ width }) => ({
    isMobile: width < 768,
    tablet: width < 992,
})

export default React.memo(withSizes(mapSizesToProps)(Info));
