import React, { forwardRef, useState } from 'react';
import style from "./LField.module.scss";
import { BiShowAlt, BiHide } from "react-icons/bi";

export const LField = forwardRef((props, ref) => {
    const [isShowHide, setIsShowHide] = useState(true)

    const { errors, icon, label, register, type, appClassName, ...rest } = props

    return <div className={`${style.main} ${appClassName}`}>
        <div
            className={`${errors[rest.name] ? style.error : ""}`}
            style={{
                gridTemplateColumns: (label || icon) && '60px 1fr',
            }}>
            {(label || icon) && <label htmlFor={rest.id}>{icon ? <img src={icon} alt="" /> : label}</label>}
            <div>
                <input
                    style={{ paddingRight: (type === 'password') && '50px' }}
                    type={!(isShowHide && type === 'password') ? 'text' : type}
                    {...register(rest.name)}
                    {...rest} />
            </div>
            {(type === 'password') && (isShowHide ? <i onClick={() => setIsShowHide(false)}><BiHide /></i> :
                <i onClick={() => setIsShowHide(true)}><BiShowAlt /></i>)}
            {errors[rest.name] && <p>{errors[rest.name].message}</p>}
        </div>
    </div>
})
