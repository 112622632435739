import React, { useEffect, useState } from 'react';
import style from './GroupProps.module.scss';
import { useDispatch } from 'react-redux';

const Groupprops = ({ data }) => {
    let content;

    if (data === null) {
        content = <div>Loading</div>
    } else if (data.groupProps !== undefined) {
        content = <div className={style.main}>
            <div>
                {data.groupProps[0].基本信息.map((el, i) => <div key={i}>
                    <div>
                        <div>
                            {Object.keys(el)[0]}
                        </div>
                        <div className={style.dot_leaders}></div>
                        <div>
                            {Object.values(el)[0]}
                        </div>
                    </div>
                </div>)}
            </div>
        </div>
    } else {
        content = <div>Нету информация!</div>
    }

    return content
}

export default React.memo(Groupprops);
