import React, { useEffect } from 'react';
import style from "./Profile.module.scss";
import { Route, Routes, NavLink } from "react-router-dom";
import { Order, Main } from "./index"

const Profile = () => {
    const about = JSON.parse(localStorage.getItem('about'))

    let content;

    if (about?.token) {
        content = <div className={style.main}>
            <aside>
                <div>
                    <ul>
                        {[{ pathname: "/profile/order", name: "Заказы" }].map((el, i) => <li key={i}>
                            <NavLink
                                to={el.pathname}
                                className={({ isActive }) =>
                                    isActive ? style.active : ""
                                }
                                end>
                                {el.name}
                            </NavLink>
                        </li>)}
                    </ul>
                </div>
            </aside>
            <div className={style.right}>
                <Routes>
                    <Route path="/" element={<Main />} />
                    <Route path="/order" element={<Order />} />
                </Routes>
            </div>
        </div >
    } else {
        content = <div>Авторизуйтесь пожалуйста!</div>
    }

    return content
}

export default Profile;