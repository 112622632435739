import React, { useState } from 'react';
import style from "./Order.module.scss";
import { toast } from "react-toastify";
import { useDispatch } from 'react-redux';
import { OrderCard } from './../Components';
import { TitleCard, Select } from '@components';
import { getOrderCustomerApi } from '@http/Components/Order/Order';

export const Order = () => {
    const dispatch = useDispatch();
    const [type, setType] = useState("my-order");
    const [orderCustomer, setOrderCustomer] = useState(null);
    const about = JSON.parse(localStorage.getItem('about'))

    const handlerSort = (id) => {
        setType(id)
        if (id === "request") {
            dispatch(getOrderCustomerApi()).then(res => {
                setOrderCustomer(res)
            })
                .catch(function (error) {
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    }
                })
        }
    }

    return <div className={style.main}>
        <div>
            <TitleCard name="Заказы" />
            {about?.role === "admin" && <div>
                <Select
                    value="По заказам"
                    name={"sortSelect"}
                    data={[
                        { id: "my-order", name: "Заказы" },
                        { id: "request", name: "Заявки" },
                    ]}
                    onSelect={(value) => handlerSort(value)}
                />
            </div>}
        </div>
        <div className={style.content}>
            {type === "request" && <div className={style.orderCustomer}>
                <div>
                    <div><OrderCard data={orderCustomer} /></div>
                </div>
            </div>}
        </div>
    </div>
}

