import React, { useState, useRef, useMemo } from 'react';
import style from "./Search.module.scss";
import { BiSearch } from "react-icons/bi";
import { MdOutlineClear, MdOutlinePhotoCamera } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { focusAC } from "@store/Reducers/Header/Search"
import { useSearchParams } from "react-router-dom";

const Search = (props) => {
    const fileInputRef = useRef(null);
    const dispatch = useDispatch()
    const navigate = useNavigate();
    let [searchParams] = useSearchParams();
    let { q } = Object.fromEntries([...searchParams])
    const [inputValue, setInputValue] = useState(q);
    const { placeholder, ...rest } = props

    const searchLink = useMemo(() => {
        if (inputValue && inputValue.length > 0) {
            return {
                pathname: '/search',
                search: `?q=${inputValue}`,
            };
        } else {
            return "#";
        }
    }, [inputValue]);

    const updateValue = (newValue) => {
        setInputValue(newValue);
    };

    const handleFileSelected = (event) => {
        const files = event.target.files;
        navigate("/image", {
            state: {
                files: files
            }
        });
    }

    const handlerImage = () => {
        fileInputRef.current.click();
    }

    const onFormSubmit = e => {
        e.preventDefault();
        dispatch(focusAC(false))
        if (inputValue.length > 0)
            navigate(`/search?q=${inputValue}`)
    }
    return <form onSubmit={onFormSubmit} className={style.main}>
        <div>
            <div>
                <input
                    type="text"
                    value={inputValue ? inputValue : ""}
                    onChange={(input) => updateValue(input.target.value)}
                    placeholder={placeholder ? placeholder : ""}
                    onBlur={() => dispatch(focusAC(false))}
                    onFocus={() => dispatch(focusAC(true))} />
                {/* <div>
                    <button><MdOutlineClear /></button>
                    <div className={style.searchFormSeparator}></div>
                </div> */}
            </div>
        </div>
        <div onClick={handlerImage} className={style.photoImage}>
            <MdOutlinePhotoCamera />
            <input ref={fileInputRef} onChange={handleFileSelected} type="file" accept="image/jpeg,image/png,image/gif" title="Поиск по фотографии" />
        </div>
        <Link to={searchLink}>
            <BiSearch />
        </Link>
    </form>
}

export default Search;
