import React, { useEffect } from "react"
import View from "./view";
import ReactGA from 'react-ga';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { useLocation } from "react-router-dom";
import { useYatranslate } from "@utils/useYatranslate";

ReactGA.initialize('UA-56825507-2');

const App = () => {
  const location = useLocation()
  useYatranslate();

  useEffect(() => {
    const currentPath = location.pathname + location.search
    ReactGA.pageview(currentPath);
  }, [location])

  return <div className="App">
    <div className="lang lang_fixed">
      <div id="ytWidget" style={{ display: 'none' }}></div>
    </div>
    <View />
    <ToastContainer position="bottom-right" autoClose={5000} />
  </div>
}

export default App;
