import React from 'react';
import style from './Seller.module.scss';
import { useQuery } from 'react-query';
import { useSearchParams } from "react-router-dom";
import { getSellerApi } from "@http/Main/Seller";
import { http_build_query } from "@utils/NFormatter";
import { Seller } from "../Detail/Components/index";
import { Loading, MasonryContainer } from '@components';

const SellerItem = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    let payload = Object.fromEntries([...searchParams])

    const { isError, isLoading, data } = useQuery(http_build_query(payload), getSellerApi(payload), { refetchOnWindowFocus: false });

    if (isLoading) return <Loading />;

    if (isError || data === undefined) return <div>Ошибка сервера!</div>;
    
    const { shopList } = data.shopList

    let info = {
        shopId: shopList[0].shopId,
        shopIcon: shopList[0].picUrl,
        sellerNick: shopList[0].shopName,
        evaluates: [
            {
                "score": shopList[0].deliverScore,
                "type": "post"
            },
            {
                "score": shopList[0].serviceScore,
                "type": "serv"
            },
            {
                "score": shopList[0].descriptionMatchScore,
                "type": "desc"
            }
        ],
    }

    return <div className={style.main}>
        <div className={style.right}>
            <Seller data={info} />
        </div>
        <div className={style.left}>
            <MasonryContainer block="search" data={data.items} />
        </div>
    </div>
}

export default SellerItem;
